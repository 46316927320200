<template>
    <div>
        <div class="mobile_wrap" v-if="topNavShow">
            <mobNav :activeNav="activeNav"></mobNav>
        </div>

        <div class="mainview_itoken_wrap">
            <div class="repayment_wrap">
                <div class="repayment_cont">
                    <div class="repayment_head">
                        <div class="repayment_hleft">Pendiente de pago</div>
                        <div class="repayment_hright" @click="detailShow = true">
                            <div class="repayment_hrtext">Detalles</div>
                            <div class="repayment_hricon">
                                <img src="@/assets/mobimg/repayment_arrowright.png" alt="">
                            </div>
                        </div>
                    </div>
                    <template v-if="repaymentInfo">
                        <div class="repayment_amount" :class="repaymentInfo.order_status == 9 ?'repayment_amount_two' : ''">
                            <div><span>$</span>{{repaymentInfo.unpaid_amount}}</div>
                            <div class="repayment_amview" v-if="repaymentInfo.order_status == 9">
                                <div class="repayment_amvimg">
                                    Atrasado
                                </div>
                            </div>
                        </div>
                    </template>

                    <div class="repayment_times" v-if="repaymentInfo">
                        <div>Fecha final de pago:</div>
                        <span>{{repaymentInfo.repay_time}}</span>
                    </div>
                    <div class="repayment_coupons" @click="gotoCoupons">
                        <div class="repayment_coupons_view">
                            <div class="repayment_cupsleft">
                                <div class="repayment_cupslicon">
                                    <img src="@/assets/mobimg/repayment_cupslicon.png" alt="">
                                </div>
                                <div class="repayment_cupsltext">Cupón</div>
                            </div>

                            <div class="repayment_cupsright">
                                <!--<div class="repayment_cupsrtext"><span>3</span>disponible(s)</div>
                                <div class="repayment_cupsricon">
                                    <img src="@/assets/mobimg/repayment_arrowright.png" alt="">
                                </div>-->

                                <div class="repayment_cupsrtext">
                                    <template v-if="couponsList && couponsList.length">
                                        <template v-if="couponsObj">
                                            <template v-if="couponsObj.coupon_type == 1">
                                                <p style="color: #DB861F;">
                                                    <span style="color: #DB861F;">-</span>${{couponsObj.distribute_amount}}
                                                </p>
                                            </template>
                                            <template v-else-if="couponsObj.coupon_type == 4">
                                                <p style="color: #DB861F;">
                                                    <span style="color: #DB861F;">+</span>${{couponsObj.distribute_amount}}
                                                </p>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <span>{{couponsList.length}}</span>disponible(s)
                                        </template>
                                    </template>
                                    <template v-else>
                                        No utilizar el cupón
                                    </template>
                                </div>

                                <div class="repayment_cupsricon">
                                    <img src="@/assets/mobimg/repayment_arrowright.png" alt="">
                                </div>

                            </div>
                        </div>
                        <div class="itoken_check_vidown" v-if="couponsObj">
                            <div class="itoken_check_vidleft"></div>
                            <div class="itoken_check_vidright">{{couponsObj.name}}</div>
                        </div>

                    </div>

                    <div class="repayment_middle">
                        <div class="repayment_mtop">
                            <div class="repayment_mtview">
                                <div class="repayment_mttitle">Pago con tarjeta bancaria</div>
                                <div class="repayment_mtone">
                                    <div class="repayment_mtone_cont" @click="cardShow = true">
                                        <div class="repayment_mtonecleft">
                                            <img src="@/assets/mobimg/payment_spe.png" alt="">
                                        </div>
                                        <div class="repayment_mtonecright">
                                            <div class="repayment_mtonecr_cont">
                                                <input type="text" :value="card_val" />
                                                <div class="repayment_mtonecr_dv"></div>
                                            </div>
                                            <div class="repayment_mtonecr_icon">
                                                <img src="@/assets/mobimg/repayment_cupsricon.png" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="repayment_mttwo">
                                    <div class="repayment_mttwo_cont">
                                        <div class="repayment_mttwo_cleft">
                                            Pagar en línea. Comisión <span>0 MXN</span>
                                        </div>
                                        <template v-if="card_val">
                                            <div class="repayment_mttwo_cright" @click="copy(card_val)">
                                                <span>Copiar</span>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="repayment_mttwo_cright">
                                                <span>Copiar</span>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                                <div class="repayment_mtthr">
                                    <div class="repayment_mtthr_cont" v-if="repaymentInfo">
                                        <div class="repayment_mtthr_cleft">
                                            <div class="repayment_mtthr_clicon">
                                                <template v-if="repaymentInfo.order_status == 9">
                                                    <van-icon size="16" name="warning" color="#BDA170" />
                                                </template>
                                                <template v-else>
                                                    <van-icon size="16" name="warning" color="#A7A7A7" />
                                                </template>
                                            </div>
                                        </div>
                                        <div class="repayment_mtthr_cright" :class="repaymentInfo.order_status == 9 ? 'repayment_mtthr_crtwo': ''">
                                            Puedes usar las referencias de abajo si la primera no funciona bien.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="repayment_down">

                            <template v-if="offline_card_list && offline_card_list.length">
                                <template v-for="(item, index) in offline_card_list">

                                    <div v-if="offline_card_list.length > 1" :key="index" class="repayment_ditems offline_card_listrepayment_dfrscale-1px">
                                        <div class="repayment_ditem">
                                            <div class="repayment_dititle">
                                                <template v-if="item.offline_card_title">
                                                    {{item.offline_card_title}}
                                                </template>
                                                <template v-else> Reembolso de la tienda</template>
                                            </div>
                                            <div class="repayment_dmview">
                                                <div class="repayment_dmleft">
                                                    <div class="repayment_dmlimg">
                                                        <!--<img src="@/assets/mobimg/payment_711.png" alt="">-->
                                                        <img :src="item.logo_pic" alt="">
                                                    </div>
                                                </div>
                                                <div class="repayment_dmright">
                                                    {{item.offline_des}}
                                                </div>
                                            </div>
                                            <div class="repayment_dfview">
                                                <div class="repayment_dfleft">Comisión<span>10 MAX</span></div>
                                                <div class="repayment_dfright">
                                                    <span>q'e</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-else :key="index" class="repayment_ditems">
                                        <div class="repayment_ditem">
                                            <div class="repayment_dititle">
                                                <template v-if="item.offline_card_title">
                                                    {{item.offline_card_title}}
                                                </template>
                                                <template v-else> Reembolso de la tienda</template>
                                            </div>
                                            <div class="repayment_dmview">
                                                <div class="repayment_dmleft">
                                                    <div class="repayment_dmlimg">
                                                        <!--<img src="@/assets/mobimg/payment_711.png" alt="">-->
                                                        <img :src="item.logo_pic" alt="">
                                                    </div>
                                                </div>
                                                <div class="repayment_dmright">
                                                    {{item.offline_des}}
                                                </div>
                                            </div>
                                            <div class="repayment_dfview">
                                                <div class="repayment_dfleft">Comisión<span>{{item.handling_fee}} MAX</span></div>
                                                <div class="repayment_dfright" @click.stop="gotoOnlinePay(item)">
                                                    <span>q'e</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </template>


                            <!--<div class="repayment_ditems repayment_dfrscale-1px">
                                <div class="repayment_ditem">
                                    <div class="repayment_dititle">Reembolso de la tienda</div>
                                    <div class="repayment_dmview">
                                        <div class="repayment_dmleft">
                                            <div class="repayment_dmlimg">
                                                <img src="@/assets/mobimg/payment_711.png" alt="">
                                            </div>
                                        </div>
                                        <div class="repayment_dmright">
                                            También puedes pagar en el 7-ELEVEN, Farmacias del Ahorro, Walmart y otras tiendas...
                                        </div>
                                    </div>
                                    <div class="repayment_dfview">
                                        <div class="repayment_dfleft">Comisión<span>10 MAX</span></div>
                                        <div class="repayment_dfright">
                                            <span>q'e</span>
                                        </div>
                                    </div>
                                </div>
                            </div>-->

                        </div>

                    </div>



                </div>
            </div>
            <div class="com_fc_down" v-if="$store.state.common.pagePayment">
                <div class="com_fc_dview">
                    <img src="@/assets/mobimg/comf_appdload.png" alt="" @click="onAppLoad">
                    <div class="com_fc_dvclose" @click="onAppLoadClose">
                        <img src="@/assets/mobimg/comf_rpclose.png" alt="">
                    </div>
                </div>
            </div>
        </div>


        <van-popup v-model="cardShow" position="bottom" round>
            <div class="verifycode_wrap">
                <div class="verifycode_cont">
                    <div class="verifycode_tilte"></div>
                    <div class="verifycode_top verifycode_coupons_top verifycode_coupons_top_new">
                        <div class="verifycode_tleft">
                            <span>
                                Plan de reembolso
                            </span>
                        </div>
                        <div class="verifycode_tright" @click="cardShow = false">
                            <img src="@/assets/mobimg/verifycode_tright.png" alt="">
                        </div>
                    </div>



                    <div class="recard_list">
                        <div class="recard_tite_wrap">
                            <div class="repayment_mtthr">
                                <div class="repayment_mtthr_cont">
                                    <div class="repayment_mtthr_cleft">
                                        <div class="repayment_mtthr_clicon">
                                            <van-icon size="18" name="warning" color="#BDA170" />
                                        </div>
                                    </div>
                                    <div class="repayment_mtthr_cright repayment_mtthr_crtwo">
                                        Puedes usar las referencias de abajo si la primera no funciona bien.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <template v-if="card_list && card_list.length">
                            <div class="recard_items" v-for="(item, index) in card_list" :key="index">
                                <div class="recard_item_left">
                                    <span>{{item.card_no}}</span>
                                </div>
                                <div class="recard_item_right" @click="copy(item.card_no)"><span>Copiar</span></div>
                            </div>
                        </template>

                    </div>

                </div>
            </div>
        </van-popup>


        <van-popup v-model="detailShow" position="bottom" round>
            <div class="verifycode_wrap">
                <div class="verifycode_cont">
                    <div class="verifycode_tilte"></div>
                    <div class="verifycode_top verifycode_coupons_top">
                        <div class="verifycode_tleft">
                            <span>
                                Detalles
                            </span>
                        </div>
                        <div class="verifycode_tright" @click="detailShow = false">
                            <img src="@/assets/mobimg/verifycode_tright.png" alt="">
                        </div>
                    </div>

                    <div class="redetail_coupons_form" v-if="repaymentInfo">
                        <div class="redetail_wrap">
                            <div class="redetail_title">
                                <div class="redetail_tleft"></div>
                                <div class="redetail_tright">Información de pago</div>
                            </div>
                        </div>

                        <div class="redetail_cont" style="padding-top: 0.555556rem;">
                            <div class="confirmloan_msgitems">
                                <div class="confirmloan_msgileft">Fecha de pago：</div>
                                <div class="confirmloan_msgiright">{{repaymentInfo.repay_time}}</div>
                            </div>

                            <div class="confirmloan_msgitems detail_scale-1px">
                                <div class="confirmloan_msgileft" style="color: #DB861F;">Días de retraso：</div>
                                <div class="confirmloan_msgiright" style="color: #DB861F;">
                                    <template v-if="repaymentInfo.order_status == 9">
                                        {{Math.abs(repaymentInfo.overdue_days)}} dias
                                    </template>
                                    <template v-else>
                                        0 dias
                                    </template>

                                </div>
                            </div>

                        </div>

                        <div class="redetail_cont" style="padding-top: 0.388889rem; padding-bottom: 0.5rem;">
                            <div class="confirmloan_msgitems">
                                <div class="confirmloan_msgileft">Monto de pago:</div>
                                <div class="confirmloan_msgiright">$<span>{{repaymentInfo.total_amount_due}}</span></div>
                            </div>
                            <div class="confirmloan_msgitems">
                                <div class="confirmloan_msgileft">Pendiente a pagar:</div>
                                <div class="confirmloan_msgiright">$<span>{{repaymentInfo.unpaid_amount}}</span></div>
                            </div>
                            <div class="confirmloan_msgitems">
                                <div class="confirmloan_msgileft">Monto pagado (incluido el monto reducido):</div>
                                <div class="confirmloan_msgiright">$<span>{{repaymentInfo.paid_amount}}</span></div>
                            </div>
                        </div>

                        <div class="redetail_wrap">
                            <div class="redetail_title">
                                <div class="redetail_tleft"></div>
                                <div class="redetail_tright">Información de préstamos </div>
                            </div>
                        </div>

                        <div class="redetail_cont" style="padding-top: 0.555556rem;">
                            <div class="confirmloan_msgitems">
                                <div class="confirmloan_msgileft">Fecha de depósito:</div>
                                <div class="confirmloan_msgiright">{{repaymentInfo.loan_time}}</div>
                            </div>
                            <div class="confirmloan_msgitems">
                                <div class="confirmloan_msgileft">Monto de préstamo:</div>
                                <div class="confirmloan_msgiright">$<span>{{repaymentInfo.loan_amount}}</span></div>
                            </div>
                            <div class="confirmloan_msgitems">
                                <div class="confirmloan_msgileft">Interés:</div>
                                <div class="confirmloan_msgiright">$<span>{{repaymentInfo.interest}}</span></div>
                            </div>
                            <div class="confirmloan_msgitems">
                                <div class="confirmloan_msgileft">Comisión:</div>
                                <div class="confirmloan_msgiright">$<span>{{repaymentInfo.service_fee}}</span></div>
                            </div>
                            <div class="confirmloan_msgitems">
                                <div class="confirmloan_msgileft">VAT:</div>
                                <div class="confirmloan_msgiright">$<span>{{repaymentInfo.vat}}</span></div>
                            </div>
                            <div class="confirmloan_msgitems">
                                <div class="confirmloan_msgileft">Interés penalizado:</div>
                                <div class="confirmloan_msgiright">$<span>{{repaymentInfo.penalty_amount}}</span></div>
                            </div>
                            <div class="confirmloan_msgitems">
                                <div class="confirmloan_msgileft">Monto reducido (incluido los cupones):</div>
                                <div class="confirmloan_msgiright">$<span>{{repaymentInfo.reduced_amount}}</span></div>
                            </div>
                        </div>

                    </div>

                    <div class="verifycode_footer_btns">
                        <div class="verifycode_footer_btn">
                            <van-button round color="#00CA88" block @click="detailShow = false">
                                <span style="font-weight: 500;">Enterado</span>
                            </van-button>
                        </div>
                    </div>

                </div>
            </div>
        </van-popup>


    </div>
</template>

<script>

    import { loadingShow, loadingHide } from '@/utils/loading'

    import mobNav from "@/components/mobNav.vue";
    import scrollUpdown from "@/mixins/index";

    import store from '@/store';

    import { loaninfo, couponList, homeOrder, couponUnused } from '@/api/loan';

    export default {
        name: 'repayment',
        components: { mobNav },
        mixins: [scrollUpdown],
        data() {
            return {
                activeNav: 'repayment',
                detailShow: false,
                cardShow: false,
                couponsList: [
                    /*{
                        "id": 1281,
                        "ctime": 1678955599368,
                        "coupon_id": 268,
                        "coupon_type": 1,
                        "valid_days": 4,
                        "distribute_count": 100,
                        "distribute_amount": 150,
                        "effective_date": 1678955599367,
                        "expire_date": 1679291999000,
                        "is_available": 1,
                        "send_amount": 150,
                        "name": "Cupón de pago",
                        "tag": "",
                        "limit_amount": 300
                    }, {
                        "id": 1282,
                        "ctime": 1678955599434,
                        "coupon_id": 269,
                        "coupon_type": 4,
                        "valid_days": 3,
                        "distribute_count": 1000,
                        "distribute_amount": 100,
                        "effective_date": 1678955599434,
                        "expire_date": 1679205599000,
                        "is_available": 1,
                        "send_amount": 100,
                        "name": "Cupón de monto adicional",
                        "tag": "",
                        "limit_amount": 0
                    }*/
                ],
                couponsObj: null,
                card_val: null,
                repaymentInfo: null,
                card_list: [],
                offline_card_list: [],
            }
        },
        mounted() {
            window.addEventListener('scroll',this.handleScroll);

            this.$store.commit('common/SET_ONLINE_CODE', null);

            this.getHomeOrder();
            this.getCouponList();
            this.getloanInfo();

            /*let usecoupons = this.$store.state.common.coupons;
            if(usecoupons && JSON.parse(usecoupons).coupon_type){
                usecoupons = JSON.parse(usecoupons);
                this.couponsObj = usecoupons;
            }*/
        },
        methods: {
            onAppLoad() {
                console.log('下载app')
                window.location.href = 'https://www.rapidayuda.com/app/Rapidayuda_releasenext.apk';
            },
            onAppLoadClose(){
                this.$store.commit('common/SET_PAGE_PAYMENT', false);
            },
            gotoOnlinePay(data){
                this.$store.commit('common/SET_ONLINE_CODE', data);
                console.log(data)
                this.$router.push({
                    path: '/compoundloan/repaymentonline',
                })
            },
            copy(value) {
                var domUrl = document.createElement("input");
                domUrl.value = value;
                domUrl.id = "creatDom";
                document.body.appendChild(domUrl);
                domUrl.select(); // 选择对象
                document.execCommand("Copy"); // 执行浏览器复制命令
                let creatDom = document.getElementById("creatDom");
                creatDom.parentNode.removeChild(creatDom);
                this.$toast("Copia con éxito");
                return;
            },
            getHomeOrder() {
                let _this = this;
                let useaccessToken = this.$store.state.common.accessToken;
                let usepageType = this.$store.state.common.pageType;

                let postData = {
                    "access_token": useaccessToken,
                    "source":"Rapidayuda",
                    "channel":usepageType
                };

                loadingShow();

                homeOrder(postData).then(res => {
                    console.log(res)

                    loadingHide();

                    if(res.code == 0){
                        let useresult = res.result;
                        if(useresult){
                            let card_list = useresult.info.card_list;
                            let offline_card_list = useresult.info.offline_card_list;

                            if(card_list && card_list.length){
                                _this.card_val = card_list[0].card_no
                            }

                            _this.repaymentInfo = useresult.info;

                            _this.card_list = card_list;
                            _this.offline_card_list = offline_card_list;

                            if(useresult.info.unpaid_amount == 0){ //未还金额为0
                                _this.$router.push({
                                    path: '/compoundloan/indextoken'
                                })
                            }

                        }
                    }

                    //this.$toast.clear();
                }, error => {
                    loadingHide();
                })
            },
            gotoCoupons() {
                this.$router.push({
                    path: '/compoundloan/coupons',
                    query: {
                        pageType: 'repayment'
                    }
                })
            },
            getCouponList() { //获取优惠券列表
                /*let postData = {
                    "access_token": useaccessToken,
                    "list_type": 1, //1未使用 2已使用 3已过期
                    "offset": 1,
                    "coupon_type": 1, //1减免、4提额
                };*/

                let _this = this;

                let useaccessToken = this.$store.state.common.accessToken;
                let postData = {
                    "access_token": useaccessToken,
                    "list_type": 1, //1未使用 2已使用 3已过期
                    "coupon_type": 1, //1减免、4提额
                };

                couponUnused(postData).then(res => {
                    console.log(res)
                    if(res.code == 0){
                        let useData = res.data;
                        let usableCoupons = useData.usableCoupons;
                        _this.couponsList = usableCoupons;
                    }
                })
            },
            getloanInfo(){ //获取订单状态
                let _this = this;
                let useaccessToken = this.$store.state.common.accessToken;
                let usepageType = this.$store.state.common.pageType;

                //let useaccessToken = '6035915f182f42835b012b81a246346e'

                let postData = {
                    "access_token": useaccessToken,
                    "source":"Rapidayuda",
                    "channel":usepageType
                };

                loaninfo(postData).then(res => {
                    console.log(res)
                })

            },
        }
    }
</script>

<style>
    .repayment_wrap{
        width: 100%;
        height: auto;
        background: #F6F6F6 url(../../assets/mobimg/repayment_banner.png) no-repeat top right;
        background-size: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0.388889rem 0.444444rem 3.222222rem 0.444444rem;
    }
    .repayment_cont{
        width: 100%;
        height: auto;
    }
    .repayment_head{
        width: 100%;
        height: 1.444444rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .repayment_hleft{
        width: auto;
        height: 0.555556rem;
        font-size: 0.444444rem;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 0.555556rem;
    }
    .repayment_hright{
        width: auto;
        height: 0.555556rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .repayment_hrtext{
        width: auto;
        height: 0.555556rem;
        font-size: 0.388889rem;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 0.555556rem;
        padding-right: 0.111111rem;
    }
    .repayment_hricon{
        width: 0.277778rem;
        height: 0.277778rem;
    }
    .repayment_hricon img{
        display: block;
        width: 100%;
    }
    .repayment_amount{
        width: 100%;
        height: 1.222222rem;
        font-size: 1.111111rem;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 1.111111rem;
        display: flex;
        justify-content: flex-start;
    }
    .repayment_amount span{
        font-size: 0.611111rem;
        padding-right: 0.111111rem;
    }
    .repayment_amount_two{
        color: #FFE574;
    }
    .repayment_amview{
        width: 2.083333rem;
        height: 1.222222rem;
        position: relative;
    }
    .repayment_amvimg{
        width: 1.833333rem;
        height: 0.583333rem;
        position: absolute;
        left: 0.25rem;
        top: -0.305556rem;
        background: url(../../assets/mobimg/repayment_amvimg.png) no-repeat top right;
        background-size: 100%;
        font-size: 12px;
        font-weight: 500;
        color: #957900;
        line-height: 0.583333rem;
        text-align: center;
    }
    .repayment_times{
        width: 100%;
        height: 0.9rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .repayment_times div{
        width: auto;
        height: 20px;
        font-size: 13px;
        font-weight: 500;
        color: #FFFFFF;
    }
    .repayment_times span{
        display: block;
        width: auto;
        height: 20px;
        font-size: 13px;
        color: #FFE574;
        font-weight: bold;
        padding-left: 0.2rem;
        line-height: 20px;
    }
    .repayment_coupons{
        width: 100%;
        min-height: 1.111111rem;
        margin: 0.277778rem auto 0.444444rem auto;
       /* background: #15E59D url(../../assets/mobimg/repayment_bgimg01.png) no-repeat top right;
        background-size: 100%;*/
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 0.444444rem 0.333333rem 0.444444rem;
        border-radius: 0.277778rem;
        background: #15E59D;
    }
    .repayment_coupons_view{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 0.333333rem;
    }
    .repayment_cupsleft{
        width: auto;
        height: 0.444444rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .repayment_cupslicon{
        width: 0.444444rem;
        height: 0.444444rem;
    }
    .repayment_cupslicon img{
        display: block;
        width: 100%;
    }
    .repayment_cupsltext{
        width: auto;
        height: 0.444444rem;
        font-size: 0.361111rem;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 0.444444rem;
        padding-left: 0.166667rem;
    }
    .repayment_cupsright{
        width: auto;
        height: 0.444444rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .repayment_cupsrtext{
        width: auto;
        font-size: 0.361111rem;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 0.361111rem;
        padding-right: 2px;
    }
    .repayment_cupsrtext span{
        padding-right: 0.083333rem;
    }
    .repayment_cupsricon{
        width: 0.277778rem;
        height: 0.277778rem;
    }
    .repayment_cupsricon img{
        display: block;
        width: 100%;
    }
    .repayment_middle{
        width: 100%;
        height: auto;
        background: #FFFFFF;
        border-radius: 0.333333rem;
    }
    .repayment_mtop{
        width: 100%;
        height: auto;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0.083333rem 0.083333rem 0 0.083333rem;
    }
    .repayment_mtview{
        width: 100%;
        height: 5.361111rem;
        background: linear-gradient(270deg, #FFF1D7 0%, #FFF7E9 100%);
        border-radius: 0.277778rem 0.277778rem 0px 0px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0.166667rem 0.361111rem 0.555556rem 0.361111rem;
    }
    .repayment_mttitle{
        width: 100%;
        height: 1.055556rem;
        font-size: 0.444444rem;
        font-weight: 500;
        color: #1A1A1A;
        line-height: 1.055556rem;
    }
    .repayment_mtone{
        width: 100%;
        height: 1.527778rem;
    }
    .repayment_mtone_cont{
        width: 100%;
        height: 1.111111rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .repayment_mtonecleft{
        width: 1.111111rem;
        height: 1.111111rem;
    }
    .repayment_mtonecright{
        width: 5.222222rem;
        height: 0.833333rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border: 1px solid #D5C6AB;
        border-radius: 0.111111rem;
        padding: 0 0.277778rem 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .repayment_mtonecr_cont{
        width: 4.166667rem;
        height: 0.833333rem;
        display: flex;
        align-items: center;
        position: relative;
    }
    .repayment_mtonecr_cont input{
        display: block;
        width: 100%;
        height: 0.666667rem;
        border: 0;
        outline: none;
        background: none;
        padding-left: 0.3rem;
        font-size: 12px;
        font-weight: 500;
        color: #1A1A1A;
        line-height: 0.666667rem;
    }
    .repayment_mtonecr_dv{
        width: 4.166667rem;
        height: 0.833333rem;
        position: absolute;
        left: 0;
        top: 0;
    }
    .repayment_mtonecr_icon{
        width: 0.277778rem;
        height: 0.277778rem;
    }
    .repayment_mtonecr_icon img{
        display: block;
        width: 100%;
        height: 100%;
    }
    .repayment_mttwo{
        width: 100%;
        height: 1.361111rem;
    }
    .repayment_mttwo_cont{
        width: 100%;
        height: 0.833333rem;
        display: flex;
        justify-content: space-between;
    }
    .repayment_mttwo_cleft{
        width: auto;
        height: 0.833333rem;
        font-size: 12px;
        font-weight: 500;
        color: #343E3A;
        line-height: 0.833333rem;
    }
    .repayment_mttwo_cleft span{
        font-weight: bold;
        color: #DB861F;
    }
    .repayment_mttwo_cright{
        width: 1.888889rem;
        height: 0.833333rem;
        background: #00CA88;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.416667rem;
    }
    .repayment_mttwo_cright span{
        font-size: 13px;
        font-weight: 500;
        color: #FFFFFF;
    }
    .repayment_mtthr{
        width: 100%;
        height: auto;
    }
    .repayment_mtthr_cont{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-start;
    }
    .repayment_mtthr_cleft{
        width: 0.833333rem;
        height: 0.833333rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .repayment_mtthr_clicon{
        width: 14px;
        height: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .repayment_mtthr_cright{
        width: auto;
        font-size: 11px;
        font-weight: 500;
        color: #808080;
        line-height: 0.416667rem;
        box-sizing: border-box;
    }
    .repayment_mtthr_crtwo{
        color: #BDA170;
    }
    .repayment_down{
        width: 100%;
        height: auto;
        box-sizing: border-box;
        padding: 0 0.444444rem 0 0.444444rem;
        background: #ffffff;
        border-radius: 0.333333rem;
    }
    .repayment_ditems{
        width: 100%;
        height: 4.083333rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-top: 0.25rem;
    }
    .repayment_ditem{
        width: 100%;
        height: auto;
    }
    .repayment_dititle{
        width: 100%;
        height: 1.055556rem;
        font-size: 0.444444rem;
        font-weight: 500;
        color: #1A1A1A;
        line-height: 1.055556rem;
    }
    .repayment_dmview{
        width: 100%;
        height: 1.388889rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .repayment_dmleft{
        width: 1.111111rem;
        height: 1.388889rem;
    }
    .repayment_dmlimg{
        width: 1.111111rem;
        height: 1.111111rem;
    }
    .repayment_dmlimg img{
        display: block;
        width: 1.111111rem;
        height: 1.111111rem;
    }
    .repayment_dmright{
        width: 5.222222rem;
        font-size: 11px;
        font-weight: 500;
        color: #808080;
        line-height: 0.416667rem;
    }
    .repayment_dfview{
        width: 100%;
        height: 0.833333rem;
        display: flex;
        justify-content:space-between;
        align-items: center;
    }
    .repayment_dfleft{
        width: auto;
        font-size: 12px;
        font-weight: 500;
        color: #343E3A;
        line-height: 12px;
    }
    .repayment_dfleft span{
        padding-left: 0.083333rem;
        color: #DB861F
    }
    .repayment_dfright{
        width: 1.888889rem;
        height: 0.833333rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border: 1px solid #00CA88;
        border-radius: 0.416667rem;
        font-size: 0.361111rem;
        font-weight: 500;
        color: #00CA88;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* 1px边框 底部*/
    .repayment_dfrscale-1px{
        position: relative;
        border:none;
    }
    .repayment_dfrscale-1px:after{
        content: '';
        position: absolute;
        bottom: 0;
        background: #E6E9E8;
        width: 100%;
        height: 1px;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
    }
    .recard_tite_wrap{
        width: 100%;
        height: auto;
        box-sizing: border-box;
        padding: 0.222222rem 0.361111rem;
        margin: 0 auto;
        background: #FFF2DC;
        border-radius: 0.333333rem;
        margin-bottom: 0.7222222rem;
        margin-top: 0.4rem;
    }
    .recard_list{
        width: 100%;
        min-height: 3.24rem;
        max-height: 11.1rem;
        overflow-x: hidden;
        overflow-y: scroll;
        box-sizing: border-box;
        padding: 0 0.444444rem 0.444444rem 0.444444rem;
    }
    .recard_items{
        width: 100%;
        height: 1.277778rem;
        display: flex;
        justify-content: space-between;
    }
    .recard_item_left{
        width: auto;
        height: 0.722222rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .recard_item_left span{
        font-size: 0.388889rem;
        font-weight: 500;
        color: #1A1A1A;
    }
    .recard_item_right{
        width: 1.583333rem;
        height: 0.722222rem;
        box-sizing: border-box;
        border-radius: 0.416667rem;
        border: .5px solid #00CA88;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .recard_item_right span{
        font-size: 12px;
        font-weight: 500;
        color: #00CA88;
    }
    .redetail_wrap{
        width: 100%;
        height: auto;
    }
    .redetail_title{
        width: 100%;
        height: 0.388889rem;
        display: flex;
        justify-content: flex-start;
    }
    .redetail_tleft{
        width: 0.055556rem;
        height: 0.388889rem;
        background: #2FA96B;
    }
    .redetail_tright{
        width: auto;
        height: 0.388889rem;
        padding-left: 0.111111rem;
        font-size: 0.388889rem;
        font-weight: 500;
        color: #1A1A1A;
        line-height: 0.388889rem;
    }
    .redetail_coupons_form{
        width: 100%;
        height: auto;
        overflow-x: hidden;
        overflow-y: scroll;
        box-sizing: border-box;
        padding: 0 0.444444rem 0.444444rem 0.444444rem;
    }

    .redetail_coupons_form .confirmloan_msgileft{
        font-size: 0.361111rem;
    }
    .redetail_coupons_form .confirmloan_msgiright{
        font-size: 0.361111rem;
    }
    .redetail_coupons_form .confirmloan_msgitems{
        height: 0.75rem;
    }
    .detail_scale-1px{
        position: relative;
        border:none;
    }
    .detail_scale-1px:after{
        content: '';
        position: absolute;
        bottom: 0;
        background: #E7E7E7;
        width: 100%;
        height: 1px;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
    }
    .verifycode_coupons_top_new{
        height: 1.5rem;
    }

</style>
