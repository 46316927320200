<template>
    <div>
        <div class="mobile_wrap" v-if="topNavShow">
            <mobNav :activeNav="activeNav"></mobNav>
        </div>

        <div class="mainview_itoken_wrap">
            <div class="itoken_wrap">
                <div class="itoken_topwrap">
                    <div class="itoken_topcont">
                        <div class="itoken_toptitle">
                            <p>Importe del préstamo</p>
                        </div>
                        <div class="itoken_topmiddle">
                            <div class="itoken_topmiddle_cont">
                                $<span>{{ruleAmount}}</span>
                            </div>
                        </div>
                        <div class="itoken_toprule_wrap">
                            <div v-if="products" id="rulerProduct"></div>
                            <div v-show="!products" id='rulerWrapper'></div>

                            <div class="itoken_toprule_left">
                                <img src="@/assets/mobimg/toprule_left01.png" alt="">
                            </div>
                            <div class="itoken_toprule_right">
                                <img src="@/assets/mobimg/toprule_right01.png" alt="">
                            </div>

                            <div class="itoken_toprule_yind" v-if="ruleshow" @touchstart="onHiddenRule($event)" @touchmove="onHiddenRule($event)" @touchEnd="onHiddenRule($event)">
                                <div class="itoken_toprule_ydview">
                                    <img src="@/assets/mobimg/rule_teach.gif" alt="">
                                </div>
                            </div>

                        </div>
                        <div class="itoken_check_cont">
                            <div class="itoken_check_view">
                                <div class="itoken_check_vitems itoken_checkvi_scale-1px" >
                                    <div class="itoken_check_vitem" @click="onDaysCheck">
                                        <div class="itoken_check_vitop">
                                            <div class="itoken_check_vitleft">Plan de reembolso</div>
                                            <div class="itoken_check_vitright">
                                                <div class="itoken_check_vitrnums">
                                                    <template v-if="products">
                                                        <span>{{products.max_period}}</span>
                                                    </template>
                                                    <template v-else>
                                                        <span>--</span>
                                                    </template>
                                                    días</div>
                                                <div class="itoken_check_vitrarrow">
                                                    <img src="@/assets/mobimg/comf_rparrow.png" alt="">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="itoken_check_vidown">
                                            <div class="itoken_check_vidleft">Fecha de reembolso</div>
                                            <div class="itoken_check_vidright">
                                                <template v-if="products">
                                                    {{products.paytime}}
                                                </template>
                                                <template v-else>
                                                    --
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="itoken_check_vitems" @click="gotoCoupons">
                                    <div class="itoken_check_vitem">
                                        <div class="itoken_check_vitop">
                                            <div class="itoken_check_vitleft_coupon">
                                                <img src="@/assets/mobimg/itoken_check_vitleft_coupon.png" alt="">
                                                <span>Cupón</span>
                                            </div>
                                            <div class="itoken_check_vitright">
                                                <div class="itoken_check_vitrnums">
                                                    <template v-if="couponsList && couponsList.length">
                                                        <template v-if="couponsObj">
                                                            <template v-if="couponsObj.coupon_type == 1">
                                                                <p style="color: #DB861F;">
                                                                    <span style="color: #DB861F;">-</span>${{couponsObj.distribute_amount}}
                                                                </p>
                                                            </template>
                                                            <template v-else-if="couponsObj.coupon_type == 4">
                                                                <p style="color: #DB861F;">
                                                                    <span style="color: #DB861F;">+</span>${{couponsObj.distribute_amount}}
                                                                </p>
                                                            </template>
                                                        </template>
                                                        <template v-else>
                                                            <span>{{couponsList.length}}</span>disponible(s)
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        No utilizar el cupón
                                                    </template>
                                                </div>
                                                <div class="itoken_check_vitrarrow">
                                                    <img src="@/assets/mobimg/comf_rparrow.png" alt="">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="itoken_check_vidown" v-if="couponsObj">
                                            <div class="itoken_check_vidleft"></div>
                                            <div class="itoken_check_vidright">{{couponsObj.name}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="itoken_topbtns" @click="gotoLiving">
                            <van-button round color="#00CA88" block style="height: 1.222222rem;">
                                <span style="font-weight: bold;">Ver mi límite</span>
                            </van-button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="com_fc_down" v-if="$store.state.common.pageToken">
                <div class="com_fc_dview">
                    <img src="@/assets/mobimg/comf_appdload.png" alt="" @click="onAppLoad">
                    <div class="com_fc_dvclose"  @click="onAppLoadClose">
                        <img src="@/assets/mobimg/comf_rpclose.png" alt="">
                    </div>
                </div>
            </div>
        </div>

        <van-popup v-model="daysShow" position="bottom" round>
            <div class="verifycode_wrap">
                <div class="verifycode_cont">
                    <div class="verifycode_tilte"></div>
                    <div class="verifycode_top verifycode_coupons_top">
                        <div class="verifycode_tleft">
                            <span>
                                Plan de reembolso
                            </span>
                        </div>
                        <div class="verifycode_tright" @click="daysShow = false">
                            <img src="@/assets/mobimg/verifycode_tright.png" alt="">
                        </div>
                    </div>

                    <div class="coupons_form">
                        <div class="coupons_list">
                            <van-radio-group v-model="radioDays" @change="onRadioChange">
                                <van-cell-group>
                                    <template v-for="(item, index) in daysList">
                                        <div class="coupons_items" :key="item.max_period" :class="index == daysList.length - 1 ? 'coupons_items_end' :''">
                                            <div class="coupons_item" :class="item.max_period == radioDays ? 'coupons_active' : ''">
                                                <van-cell clickable @click="onProduct(item)">
                                                    <template #title>
                                                        <div class="coupons_ileft">
                                                            <div class="coupons_ilname">{{item.max_period}} días</div>
                                                            <div class="coupons_ildesc">Fecha de reembolso：{{item.paytime}}</div>
                                                        </div>
                                                    </template>

                                                    <template #right-icon>
                                                        <div class="coupons_iright">
                                                            <div class="coupons_irview">
                                                                <van-radio :name="item.max_period" >
                                                                    <template #icon="props">
                                                                        <img v-if="props.checked" src="@/assets/mobimg/radio_ischeck.png" alt="">
                                                                        <img v-else src="@/assets/mobimg/radio_nocheck.png" alt="">
                                                                    </template>
                                                                </van-radio>
                                                            </div>
                                                        </div>
                                                    </template>

                                                </van-cell>
                                            </div>
                                        </div>
                                    </template>
                                </van-cell-group>
                            </van-radio-group>
                        </div>
                    </div>

                    <div class="verifycode_footer_btns">
                        <div class="verifycode_footer_btn">
                            <van-button round color="#00CA88" block @click="onDaysConfirm">
                                <span style="font-weight: bold; font-size: 16px;">Enterado</span>
                            </van-button>
                        </div>
                    </div>

                </div>
            </div>
        </van-popup>

    </div>
</template>

<script>

    import $ from 'jquery';

    function MeasureRuler(dParam){
        var param={
            wrapperId:"rulerWrapper",   //标尺ID
            max:1000,      //标尺最大刻度
            minUnit:1,     //标尺最小单位刻度
            unitSet:10,    //标尺单位单位刻度组
            min:0,         //最小值
            mult:1         //标尺单位刻度的倍数 最小是1 即每一刻度占10px
        }
        $.extend(param,dParam);

        var limitLeft=0;
        var scrollerWidth=0;
        var wrapperWidth=0;
        var pleft=0;
        var firstRand=true;
        var self=this;
        var fixedLen=param.minUnit.toString().split(".")[1]?param.minUnit.toString().split(".")[1].length:0;
        this.initRuler=function(){
            if(param.min>param.max) param.min=0
            var nhtml="";
            limitLeft=0;
            scrollerWidth=0;
            wrapperWidth=0;
            $("#"+param.wrapperId).addClass("rulerWrapper")
            $("#"+param.wrapperId).append('<div class="rulerScroller"></div><div class="rulerPointer"><div style="width: 100%;height: 100%; position: relative;"><div class="rulerPointerSolid"></div></div></div>')
            var setLen=Math.ceil((param.max-param.min)/(param.unitSet*param.minUnit))+10;
            var setWidth=param.unitSet*10*param.mult;
            for(var i=0;i<setLen;i++){
                nhtml+="<div class='sizeNo' style='left:"+(i*setWidth-setWidth/2)+"px;width:"+setWidth+"px'><span class='sizeNum'></span></div><ul  style='width:"+setWidth+"px'>";
                for(var j=0;j<(param.unitSet-1);j++){
                    nhtml+="<li style='width:"+10*param.mult+"px'></li>";
                }
                nhtml+="</ul>";
            }
            $("#"+param.wrapperId+" .rulerScroller").append(nhtml);
            var st;
            wrapperWidth=$("#"+param.wrapperId).width();
            scrollerWidth=setLen*10*param.unitSet*param.mult;

            $("#"+param.wrapperId).on('touchstart',function(e){
                var touch = e.originalEvent.targetTouches[0];
                st = touch.pageX;
            })

            $("#"+param.wrapperId).on('touchmove',function(e){
                var touch = e.originalEvent.targetTouches[0];
                var x = touch.pageX;
                var lf=$("#"+param.wrapperId+" .rulerScroller").position().left;
                var nlf=lf+x-st;

                //超过最左或者最右标尺不可滑动
                if(lf-50 > limitLeft || wrapperWidth-scrollerWidth>lf+50){
                    return;
                }
                var pointerVal=Math.floor((limitLeft-nlf)/(10*param.mult))>0?Math.floor((limitLeft-nlf)/(10*param.mult)):0;
                pointerVal=param.minUnit*pointerVal+param.min;

                if(fixedLen) pointerVal=pointerVal.toFixed(fixedLen);
                if(param.callback)  param.callback(pointerVal)
                $("#"+param.wrapperId+" .rulerScroller").css("left",nlf);

            })

            $("#"+param.wrapperId).on('touchend',function(){

                var lf=$("#"+param.wrapperId+" .rulerScroller").position().left;
                if(lf > limitLeft){
                    $("#"+param.wrapperId+" .rulerScroller").css("left",limitLeft);
                    if(param.callback) param.callback(param.min);
                    return;
                }else if(wrapperWidth-scrollerWidth>lf){
                    self.setValue(param.max);
                    return;
                }
                //var lf=$("#"+param.wrapperId+" .rulerScroller").position().left;

                var disNo=Math.round((lf-pleft)/(10*param.mult));
                var nDis=disNo*10*param.mult+pleft;
                $("#"+param.wrapperId+" .rulerScroller").css("left",nDis);
                var pointerVal=Math.floor((limitLeft-nDis)/(10*param.mult));
                pointerVal=param.minUnit*pointerVal+param.min;
                if(fixedLen) pointerVal=pointerVal.toFixed(fixedLen);
                if(param.callback)  param.callback(pointerVal)
                console.log(pointerVal)
            })

            //中间标志的位置
            if(firstRand){
                pleft=$("#"+param.wrapperId+" .rulerPointer").position().left;
            }
            var rulerLNo=0;
            for(var z=1;z<setLen;z++){
                if(z*setWidth>pleft) {
                    limitLeft=pleft-z*setWidth;
                    rulerLNo=z;
                    break;
                }
            }

            //标尺初始化数值
            this.setValue(param.value);

            //标尺刻度值
            $("#"+param.wrapperId+" .sizeNum").each(function(idx,ele){
                if(idx>=rulerLNo && idx<setLen-5){
                    var iv=(idx-rulerLNo)*param.minUnit*param.unitSet+param.min;
                    if(fixedLen) iv=iv.toFixed(fixedLen);
                    $(ele).html(iv);
                }
            })
        }
        this.setValue=function(val){
            var nval=val?val:param.min;
            if(nval>param.max) nval=param.max;
            if(nval<param.min) nval=param.min;

            var nlval=nval-param.min;

            $("#"+param.wrapperId+" .rulerScroller").css("left",limitLeft-nlval*10*param.mult/param.minUnit);
            if(param.callback)  param.callback(nval)
        };

        this.reDrawRuler=function(nParam){
            nParam.value=nParam.value?nParam.value:0;
            firstRand=false;
            if(nParam){
                $.extend(param,nParam)
            }

            fixedLen=param.minUnit.toString().split(".")[1]?param.minUnit.toString().split(".")[1].length:0;
            $("#"+param.wrapperId).html("");
            $("#"+param.wrapperId).unbind('touchstart');
            $("#"+param.wrapperId).unbind('touchend');
            $("#"+param.wrapperId).unbind('touchmove');
            this.initRuler();
        };

        this.initRuler();

    }

    import mobNav from "@/components/mobNav.vue";
    import scrollUpdown from "@/mixins/index";
    import { Toast } from 'vant';
    import { parseTime, getNextDate, getQueryString } from "@/utils/index"
    import store from '@/store';

    import { loaninfo, productInfo, couponList, createorder, couponUnused } from '@/api/loan';
    import { loadingShow, loadingHide } from '@/utils/loading'

    let ruleObj = null;

    export default {
        name: 'compoundeIndextoken',
        components: { mobNav },
        mixins: [scrollUpdown],
        data() {
            return {
                activeNav: 'indextoken',
                ruleshow: true,
                ruleAmount: 300,
                daysShow: false,
                daysList: [],
                daysCheck: null,
                radioDays: null,
                products: null,
                couponsList: [
                    /*{
                        "id": 1281,
                        "ctime": 1678955599368,
                        "coupon_id": 268,
                        "coupon_type": 1,
                        "valid_days": 4,
                        "distribute_count": 100,
                        "distribute_amount": 150,
                        "effective_date": 1678955599367,
                        "expire_date": 1679291999000,
                        "is_available": 1,
                        "send_amount": 150,
                        "name": "Cupón de pago",
                        "tag": "",
                        "limit_amount": 300
                    }, {
                        "id": 1282,
                        "ctime": 1678955599434,
                        "coupon_id": 269,
                        "coupon_type": 4,
                        "valid_days": 3,
                        "distribute_count": 1000,
                        "distribute_amount": 100,
                        "effective_date": 1678955599434,
                        "expire_date": 1679205599000,
                        "is_available": 1,
                        "send_amount": 100,
                        "name": "Cupón de monto adicional",
                        "tag": "",
                        "limit_amount": 0
                    }*/
                ],
                couponsObj: null,
                loan_info: null,
            }
        },
        mounted() {
            window.addEventListener('scroll',this.handleScroll)

            this.getloanInfo();
            this.getproductInfo();
            this.getCouponList();

            //初始化尺子
            let _this = this;

            this.$nextTick(() => {
                new MeasureRuler({
                    wrapperId:"rulerWrapper",     //容器ID，页面中写一个DIV就行 (必须)
                    max:50000,                     //刻度尺最大的刻度    (非必须，默认为1000)
                    minUnit: 50,                    //刻度尺最小刻度    (非必须，默认为1)
                    unitSet:5,                   //刻度尺单元长度    (非必须，默认是10)
                    value:20000,                      //初始化数值       (非必须，默认为1)
                    mult:1,                       //刻度值倍数，默认是最小刻度值为10px，如果定mult为3则最小刻度为30px (非必须，默认为1)
                    callback:_this.rulerSetValue,       //滑动尺子过程中的回调函数     (非必须)
                    min:300,                    //刻度尺最小刻度
                });
            });

            let usecoupons = this.$store.state.common.coupons;
            if(usecoupons && JSON.parse(usecoupons).coupon_type){
                usecoupons = JSON.parse(usecoupons);
                this.couponsObj = usecoupons;
            }

        },
        methods: {
            onAppLoad() {
                console.log('下载app')
                window.location.href = 'https://www.rapidayuda.com/app/Rapidayuda_releasenext.apk';
            },
            onAppLoadClose(){
                this.$store.commit('common/SET_PAGE_TOKEN', false);
            },
            onHiddenRule() {
                this.ruleshow = false;
            },
            gotoCoupons() {
                this.$router.push({
                    path: '/compoundloan/coupons',
                    query: {
                        pageType: 'home'
                    }
                })
            },
            getCouponList() { //获取优惠券列表
                let _this = this;

                let useaccessToken = this.$store.state.common.accessToken;
                let postData = {
                    "access_token": useaccessToken,
                    "list_type": 1, //1未使用 2已使用 3已过期
                    //"coupon_type": 4, //1减免、4提额
                };

                couponUnused(postData).then(res => {
                    console.log(res)
                    if(res.code == 0){
                        let useData = res.data;
                        let usableCoupons = useData.usableCoupons;
                        _this.couponsList = usableCoupons;
                    }
                })

            },
            creatRuler(data){
                console.log(data)
                let _this = this;
                this.products = null;
                ruleObj = null;
                setTimeout(() => {
                    let usecouponsObj = this.couponsObj;
                    if(usecouponsObj && usecouponsObj.coupon_type == 4){ //1减免券 4提额券
                        data.normal_amount = Number(data.max_amount)
                        data.min_amount = Number(data.min_amount) + Number(usecouponsObj.distribute_amount)
                        data.max_amount = Number(data.max_amount) + Number(usecouponsObj.distribute_amount)
                    }
                    this.products = data;
                    //this.ruleAmount = data.max_amount;
                    this.$nextTick(() => {
                        let useminUnit = 100;
                        if(data.max_amount - data.min_amount < 5001){
                            useminUnit = 1;
                        }
                        ruleObj = new MeasureRuler({
                            wrapperId:"rulerProduct",     //容器ID，页面中写一个DIV就行 (必须)
                            max:data.max_amount,                     //刻度尺最大的刻度    (非必须，默认为1000)
                            minUnit: useminUnit,                    //刻度尺最小刻度    (非必须，默认为1)
                            unitSet:5,                   //刻度尺单元长度    (非必须，默认是10)
                            value: data.max_amount,                      //初始化数值       (非必须，默认为1)
                            mult:1,                       //刻度值倍数，默认是最小刻度值为10px，如果定mult为3则最小刻度为30px (非必须，默认为1)
                            callback:_this.rulerSetValue,       //滑动尺子过程中的回调函数     (非必须)
                            min:data.min_amount,                    //刻度尺最小刻度
                        });
                    });
                }, 200)
            },
            onDaysConfirm() {
                let usedaysCheck = this.daysCheck;
                this.radioDays = usedaysCheck.max_period;
                this.products = usedaysCheck;
                this.creatRuler(usedaysCheck);
                this.daysShow = false;
                store.commit('common/SET_LOAN_DAYS', usedaysCheck);
            },
            onProduct(data){ //选择产品
                this.daysCheck = data;
                this.radioDays = data.max_period;
            },
            getproductInfo() { //获取产品
                let _this = this;
                let useaccessToken = this.$store.state.common.accessToken;
                let usepageType = this.$store.state.common.pageType;
                console.log(useaccessToken)
                let postData = {
                    "access_token": useaccessToken,
                    "source":"Rapidayuda",
                    "channel":usepageType
                };

                /*Toast.loading({
                    message: '请求中...',
                    forbidClick: true,
                });*/

                loadingShow();

                productInfo(postData).then(res => {
                    console.log(res)
                    //Toast.clear();
                    loadingHide();
                    if(res.code == 0){
                        let useresult = res.result;

                        if(useresult){
                            let product_suitable = useresult.product_suitable;

                            if(product_suitable && product_suitable.length > 0) {
                                for(let i=0; i<product_suitable.length; i++){
                                    let nowTime = new Date().getTime();

                                    let nowDate = parseTime(nowTime, '{y}-{m}-{d}'); //获取当前时间
                                    let useDate = getNextDate(nowDate, product_suitable[i].max_period, "{d}/{m}/{y}");

                                    product_suitable[i].paytime = useDate;
                                    console.log(useDate)
                                }

                                product_suitable.sort((a,b) => {return b.max_period - a.max_period});

                                console.log(product_suitable)

                                product_suitable[0].normal_amount = Number(product_suitable[0].max_amount);

                                _this.products = product_suitable[0];
                                _this.daysCheck = product_suitable[0];
                                _this.radioDays = product_suitable[0].max_period;
                                _this.creatRuler(product_suitable[0]);

                                store.commit('common/SET_LOAN_DAYS', product_suitable[0]);
                            }
                            _this.daysList = product_suitable;
                        }

                    }
                }, error => {
                    loadingHide()
                })

            },
            getloanInfo(){ //获取订单状态
                let _this = this;
                let useaccessToken = this.$store.state.common.accessToken;
                let usepageType = this.$store.state.common.pageType;

                //let useaccessToken = '6035915f182f42835b012b81a246346e'

                let postData = {
                    "access_token": useaccessToken,
                    "source":"Rapidayuda",
                    "channel":usepageType
                };

                loaninfo(postData).then(res => {
                    console.log(res)
                    //_this.$toast.clear();
                    loadingHide();
                    if(res.code == 0){
                        let useresult = res.result;
                        if(useresult){
                            let { loan_info } = useresult;
                            _this.loan_info = loan_info;
                        }

                    }
                })

            },
            handleScroll() {
                console.log('页面滚动状态')
                console.log(this.topNavShow)
            },
            rulerSetValue(val){
                console.log(val)
                this.ruleAmount = val;
            },
            onRadioChange(e){
                console.log(e)
                let usedaysList = this.daysList;
                /*for(let i=0; i<usedaysList.length; i++){
                    if(usedaysList[i].max_period == e){

                    }
                }*/
                //this.daysShow = false;
            },
            onDaysCheck(){
                this.daysShow = true;
                this.radioDays = this.products.max_period;
            },
            orderCreate() {
                let _this = this;
                let useruleAmount = this.ruleAmount; //借款金额
                let products = this.products; //选择的产品

                let useaccessToken = this.$store.state.common.accessToken;
                let usepageType = this.$store.state.common.pageType;

                let loan_info = this.loan_info;

                if(loan_info){
                    //1 已经创建临时订单
                    //2 提交审核 审核中
                    //3 拒绝
                    //4 人工审核
                    //22 放款前待确认
                    //5 等待放款 放款中
                    //6 放款失败
                    //7 放款成功 生成还款计划
                    //8 结清
                    //9 逾期
                    //10 失效
                    //11 部分还款
                    let { order_status } = loan_info;

                    if(order_status == 7 || order_status == 9 || order_status == 11){ //还款
                        _this.$router.push({
                            path: '/compoundloan/repayment'
                        });
                    } else if(order_status == 0 || order_status == 8 || order_status == 10){ //结清
                        /*this.$toast.loading({
                            message: '处理中...',
                            duration: 0,
                            forbidClick: true
                        });*/

                        console.log(products)

                        let usecouponsObj = this.couponsObj;

                        let setruleAmount = useruleAmount;

                        if(usecouponsObj && usecouponsObj.coupon_type){
                            if(usecouponsObj.coupon_type == 4){ //提额券
                                setruleAmount = useruleAmount - usecouponsObj.distribute_amount;
                            }
                        }

                        let postData = {
                            access_token: useaccessToken,
                            "source":"Rapidayuda",
                            channel: usepageType,
                            loan: String(setruleAmount),
                            period: String(products.max_period),
                            product_id:String(products.id),
                        };

                        console.log(postData)

                        loadingShow();

                        createorder(postData).then(res => {
                            //_this.$toast.clear();
                            loadingHide();
                            console.log(res)
                            if(res.code == 0){
                                let useresult = res.result;
                                if(useresult.current_step == 1006 || useresult.current_step == 106){
                                    //需要活体检测
                                    this.$router.push({
                                        path: '/compoundloan/living'
                                    });
                                } else {
                                    //不需要活体检测
                                    _this.$router.push({
                                        path: '/compoundloan/confirmloan'
                                    });
                                }
                            }
                        }, error => {
                            loadingHide()
                        })
                    } else {
                        _this.$router.push({
                            path: '/compoundloan/examine'
                        });
                    }

                } else {
                    /*this.$toast.loading({
                        message: '处理中...',
                        duration: 0,
                        forbidClick: true
                    });*/
                    loadingShow();
                    this.getloanInfo();
                }

            },
            gotoLiving() { //创建临时订单
                let usecouponsObj = this.couponsObj; //优惠券信息
                let useruleAmount = this.ruleAmount; //借款金额

                console.log(useruleAmount)

                if(usecouponsObj){
                    if(useruleAmount >= usecouponsObj.limit_amount){
                        store.commit('common/SET_LOANA_AMOUNT', useruleAmount);
                        this.orderCreate();
                    } else {
                        this.$toast(`El monto del préstamo debe ser superior a ${usecouponsObj.limit_amount}`);
                        //借款金额需大于
                    }
                } else {
                    store.commit('common/SET_LOANA_AMOUNT', useruleAmount);
                    this.orderCreate();
                }


            }
        }
    }
</script>

<style>
    .mainview_itoken_wrap{
        width: 100%;
        height: 100vh;
        box-sizing: border-box;
        padding-top: 1.777778rem;
        background: #F6F6F6;
        overflow-x: hidden;
    }
    .itoken_wrap{
        width: 100%;
        height: auto;
        background: #F6F6F6 url(../../assets/mobimg/indextoken_bgimg.png) no-repeat top left;
        background-size: 100%;
    }
    .itoken_topwrap{
        width: 100%;
        height: auto;
        box-sizing: border-box;
        padding: 0.888889rem 0.444444rem 0 0.444444rem;
    }
    .itoken_topcont{
        width: 100%;
        height: auto;
        background: #ffffff;
        border-radius: 0.444444rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-top: 1.111111rem;
        padding-bottom: 0.444444rem;
        position: relative;
    }
    .itoken_toptitle{
        width: 100%;
        height: auto;
        margin: 0 auto;
        font-size: 0.388889rem;
        font-weight: 500;
        color: #999999;
        line-height: 0.388889rem;
    }
    .itoken_toptitle p{
        text-align: center;
    }
    .itoken_topmiddle{
        width: 100%;
        height: 1.25rem;
        margin-top: 0.388889rem;
        display: flex;
        justify-content: center;
    }
    .itoken_topmiddle_cont{
        display: inline-block;
        width: auto;
        height: 1.25rem;
        font-size: 1.111111rem;
        font-weight: bold;
        color: #1A1A1A;
        line-height: 1.111111rem;
        border-bottom: 1px dashed #999999;
    }

    .itoken_toprule_wrap{
        width: 100%;
        height: 2.027778rem;
        position: relative;
    }
    .itoken_toprule_wrap .rulerWrapper .sizeNo{
        color: #999999;
    }
    .itoken_toprule_wrap .rulerWrapper .rulerPointerSolid{
        background: #FFA942;
    }
    .itoken_toprule_wrap .rulerWrapper{
        border-bottom: 1px solid #999999;
    }
    .itoken_toprule_wrap .rulerWrapper .rulerScroller ul{
        border-right: 1px solid #999999;
    }
    .itoken_toprule_wrap .rulerWrapper .rulerScroller li{
        border-right: 1px solid #999999;
    }

    .itoken_toprule_left{
        width: 1.388889rem;
        height: 2.027778rem;
        position: absolute;
        left: 0;
        top: 0;
    }
    .itoken_toprule_left img{
        display: block;
        width: 100%;
        height: 2.027778rem;
    }
    .itoken_toprule_right{
        width: 1.388889rem;
        height: 2.027778rem;
        position: absolute;
        right: 0;
        top: 0;
    }
    .itoken_toprule_right img{
        display: block;
        width: 100%;
        height: 2.027778rem;
    }
    .itoken_topbtns{
        width: 66.67%;
        height: 1.222222rem;
        margin: 0 auto;
        background: #00CA88;
        border-radius: 0.611111rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .itoken_topbtns span{
        font-size: 0.444444rem;
        /*font-weight: 500;*/
        color: #ffffff;
    }
    .itoken_check_cont{
        width: 100%;
        height: auto;
        padding-top: 0.194444rem;
        padding-bottom: 0.888889rem;
    }
    .itoken_check_view{
        width: 92.07%;
        height: auto;
        min-height: 3.111111rem;
        margin: 0 auto;
        background: #F6F6F6;
        border-radius: 0.166667rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 0.444444rem;
    }
    .itoken_checkvi_scale-1px{
        position: relative;
        border:none;
    }
    .itoken_checkvi_scale-1px:after{
        content: '';
        position: absolute;
        bottom: 0;
        background: #DFDCDC;
        width: 100%;
        height: 1px;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
    }
    .itoken_check_vitems{
        width: 100%;
        height: auto;
        padding-top: 0.444444rem;
        padding-bottom: 0.444444rem;
    }
    .itoken_check_vitem{
        width: 100%;
        height: auto;
    }
    .itoken_check_vitop{
        width: 100%;
        height: 0.388889rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .itoken_check_vitleft{
        width: auto;
        height: 0.388889rem;
        font-size: 0.388889rem;
        font-weight: 400;
        color: #1A1A1A;
    }
    .itoken_check_vitright{
        width: auto;
        height: 0.388889rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 0.055556rem;
    }
    .itoken_check_vitrnums{
        width: auto;
        height: 0.388889rem;
        display: flex;
        align-items: center;
        font-size: 0.388889rem;
        font-weight: 500;
        color: #1A1A1A;
    }
    .itoken_check_vitrnums span{
        font-size: 0.388889rem;
        font-weight: 500;
        color: #1A1A1A;
        padding-right: 0.111111rem;
    }
    .itoken_check_vitrarrow{
        width: 0.277778rem;
        height: 0.388889rem;
        margin-left: 0.111111rem;
    }
    .itoken_check_vitrarrow img{
        display: block;
        width: 100%;
    }
    .itoken_check_vidown{
        width: 100%;
        height: auto;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-top: 0.25rem;
        padding-right: 0.388889rem;
        display: flex;
        justify-content: space-between;
    }
    .itoken_check_vidleft{
        width: auto;
        font-size: 0.333333rem;
        font-weight: 400;
        color: #808080;
    }
    .itoken_check_vidright{
        width: auto;
        font-size: 0.333333rem;
        font-weight: 400;
        color: #808080;
    }
    .itoken_check_vitleft_coupon{
        width: auto;
        height: 0.444444rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .itoken_check_vitleft_coupon img{
        display: block;
        width: 0.444444rem;
        height: 0.444444rem;
    }
    .itoken_check_vitleft_coupon span{
        padding-left: 0.166667rem;
        font-size: 0.361111rem;
        font-weight: 400;
        color: #808080;
    }
    .itoken_toprule_yind{
        width: 100%;
        height: 2.083333rem;
        position: absolute;
        left: 0;
        top: 0;
    }
    .itoken_toprule_ydview{
        width: 5.555556rem;
        height: 2.083333rem;
        margin: 0 auto;
    }
    .itoken_toprule_ydview img{
        display: block;
        width: 5.555556rem;
        height: 2.083333rem;
    }


</style>
