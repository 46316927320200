<template>
    <div>
        <div class="mobile_wrap" v-if="topNavShow">
            <mobNav :activeNav="activeNav"></mobNav>
        </div>
        <div class="mainview_itoken_wrap">

            <div class="indexnew_banner">
                <img src="@/assets/mobimg/indexnew_banner.png" alt="">
            </div>
            <div class="indexnew_loadwrap">
                <div class="indexnew_loadbgimg">
                    <img src="@/assets/mobimg/indexnew_load.png" alt="">
                </div>
                <div class="indexnew_loadcont">
                    <div class="indexnew_loaditems" @click="onAppLoad">
                        <div class="indexnew_loadiimgs">
                            <img src="@/assets/mobimg/rp_logo.png" alt="">
                        </div>
                        <div class="indexnew_loadiname">Rapidayuda</div>
                        <div class="indexnew_loadidown">
                            <img src="@/assets/mobimg/indexnew_loadapp.png" alt="">
                        </div>
                    </div>
                    <div class="indexnew_loaditems" @click="onAppLoadvv">
                        <div class="indexnew_loadiimgs">
                            <img src="@/assets/mobimg/vv_logo.png" alt="">
                        </div>
                        <div class="indexnew_loadiname">Viva Vida</div>
                        <div class="indexnew_loadidown">
                            <img src="@/assets/mobimg/indexnew_loadapp.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="index_middle_wrap" id="index_middle_wrap">
                <div class="index_middle_cont" id="index_middle_cont">
                    <div class="index_mcont_one">
                        <img src="@/assets/mobimg/index_mcont_one.png" alt="">
                    </div>
                    <div class="index_mcont_two">
                        <img src="@/assets/mobimg/index_mcont_two.png" alt="">
                    </div>
                    <div class="index_mcont_thr">
                        <img src="@/assets/mobimg/index_mcont_thr.png" alt="">
                    </div>
                    <div class="index_mcont_fur">
                        <img src="@/assets/mobimg/index_mcont_fur.png" alt="">
                    </div>
                </div>
            </div>

            <div class="com_footer_wrap">
                <div class="com_footer_cont">
                    <div class="com_fc_title">Socios</div>
                    <div class="com_fc_items com_footer_scale-1px" @click="onAppLoadvv">
                        <div class="com_fc_ileft"><span>Viva Vida</span></div>
                        <div class="com_fc_iright">
                            <div class="com_fc_irview">
                                <img src="@/assets/mobimg/comf_rparrow.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="com_fc_title">Rapidayuda</div>
                    <div class="com_fc_items com_footer_scale-1px" @click="gotoPrivacy">
                        <div class="com_fc_ileft"><span>Aviso de Privacidad</span></div>
                        <div class="com_fc_iright">
                            <div class="com_fc_irview">
                                <img src="@/assets/mobimg/comf_rparrow.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="com_fc_items com_footer_scale-1px" @click="gotoProvision">
                        <div class="com_fc_ileft"><span>Términos y Condiciones</span></div>
                        <div class="com_fc_iright">
                            <div class="com_fc_irview">
                                <img src="@/assets/mobimg/comf_rparrow.png" alt="">
                            </div>
                        </div>
                    </div>

                    <div class="com_fc_middle_wrap">
                        <div class="com_fc_middle">
                            <div class="com_fc_mappicon">
                                <img src="@/assets/mobimg/comf_rpicon.png" alt="">
                            </div>
                            <div class="com_fc_mappname">Rapidayuda</div>
                            <div class="com_fc_mappdesc">
                                <span>INTERVALO FINANZAS, S.A. DE C.V. y su  aplicación RAPIDAYUDA</span>
                            </div>
                        </div>
                    </div>

                    <div class="com_fc_down_wrap" v-if="$store.state.common.pageNew">
                        <div class="com_fc_down">
                            <div class="com_fc_dview">
                                <img src="@/assets/mobimg/comf_appdload.png" alt="" @click="onAppLoad">
                                <div class="com_fc_dvclose" @click="onAppLoadClose">
                                    <img src="@/assets/mobimg/comf_rpclose.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="com_fc_down" v-if="$store.state.common.pageNew">
                <div class="com_fc_dview">
                    <img src="@/assets/mobimg/comf_appdload.png" alt="" @click="onAppLoad">
                    <div class="com_fc_dvclose" @click="onAppLoadClose">
                        <img src="@/assets/mobimg/comf_rpclose.png" alt="">
                    </div>
                </div>
            </div>

        </div>


    </div>
</template>

<script>

    import mobNav from "@/components/mobNav.vue";
    import scrollUpdown from "@/mixins/index";

    export default {
        name: 'compoundindexnew',
        components: { mobNav },
        mixins: [scrollUpdown],
        data() {
            return {
                activeNav: 'indexnew',
            }
        },
        mounted() {
            window.addEventListener('scroll',this.handleScroll)
        },
        methods: {
            onAppLoadvv() {
                window.location.href = 'https://vivavida.onelink.me/v0MW/4nomodtd';
            },
            onAppLoad() {
                console.log('下载app')
                window.location.href = 'https://www.rapidayuda.com/app/Rapidayuda_releasenext.apk';
            },
            onAppLoadClose(){
                console.log(1111)
                this.$store.commit('common/SET_PAGE_NEW', false);
            },
            gotoPrivacy() {
                window.location.href = '/h5/privacy_agreement.html'
            },
            gotoProvision() {
                window.location.href = '/h5/provision.html'
            },
        }
    }
</script>

<style>
    .indexnew_banner{
        width: 100%;
        height: auto;
    }
    .indexnew_banner img{
        display: block;
        width: 100%;
    }
    .indexnew_loadwrap{
        width: 100%;
        height: 3.722222rem;
        position: relative;
        padding-bottom: 1.944444rem;
    }
    .indexnew_loadbgimg{
        width: 100%;
        height: auto;
        position: absolute;
        left: 0;
        top: -1.805556rem;
    }
    .indexnew_loadbgimg img{
        display: block;
        width: 100%;
        height: 100%;
    }
    .indexnew_loadcont{
        width: 100%;
        height: 3.722222rem;
        position: absolute;
        left: 0;
        top: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0.25rem 1.555556rem 0 1.555556rem;
        display: flex;
        justify-content: space-between;
    }
    .indexnew_loaditems{
        width: 2.75rem;
        height: auto;
    }
    .indexnew_loadiimgs{
        width: 0.944444rem;
        height: 0.944444rem;
        margin: 0 auto;
    }
    .indexnew_loadiimgs img{
        display: block;
        width: 0.944444rem;
        height: 0.944444rem;
        margin: 0 auto;
    }
    .indexnew_loadiname{
        width: 100%;
        height: 0.888889rem;
        font-size: 0.388889rem;
        font-weight: 500;
        color: #1A1A1A;
        line-height: 0.722222rem;
        text-align: center;
    }
    .indexnew_loadidown{
        width: 100%;
        height: 0.75rem;
    }
    .indexnew_loadidown img{
        display: block;
        width: 100%;
        height: 0.75rem;
    }
</style>
