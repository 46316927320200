<template>
    <div>
        <div class="mobile_wrap" v-if="topNavShow">
            <mobNav :activeNav="activeNav"></mobNav>
        </div>
        <div class="mainview_wrap">
            <div class="itob_warp">

                <div class="itob_cont_top">
                    <div class="itob_cont_tname">Monto máximo hasta</div>
                    <div class="itob_cont_tnums">
                        <div class="itob_cont_tnum">
                            <span>$20,000</span>
                        </div>
                    </div>
                    <div class="itob_cont_tdesc">Préstamos 100% en línea y depósitos eficientes</div>
                    <div class="itob_ctimg01">
                        <img src="@/assets/mobimg/itob_ctimg01.png" alt="">
                    </div>
                    <div class="itob_ctimg02">
                        <img src="@/assets/mobimg/itob_ctimg02.png" alt="">
                    </div>
                    <div class="itob_ctimg03">
                        <img src="@/assets/mobimg/itob_ctimg03.png" alt="">
                    </div>
                    <div class="itob_ctimg04">
                        <img src="@/assets/mobimg/itob_ctimg04.png" alt="">
                    </div>
                    <div class="itob_cont_tbtns_wrap">
                        <div class="itob_cont_tbtns">
                            <span>Ver mi monto</span>
                        </div>
                    </div>
                </div>

                <div class="imiddle_wrap" @click="gotoLogin">
                    <div class="imiddle_cont">
                        <div class="imiddle_cimg">
                            <img src="@/assets/mobimg/index_dowm_01.png" alt="">
                        </div>
                    </div>
                </div>

                <div class="itob_downimg">
                    <img src="@/assets/mobimg/itob_downimg01.png" alt="">
                </div>
            </div>


            <div class="com_footer_wrap">
                <div class="com_footer_cont">
                    <div class="com_fc_title">Socios</div>
                    <div class="com_fc_items com_footer_scale-1px" @click="onAppLoadvv">
                        <div class="com_fc_ileft"><span>Viva Vida</span></div>
                        <div class="com_fc_iright">
                            <div class="com_fc_irview">
                                <img src="@/assets/mobimg/comf_rparrow.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="com_fc_title">Rapidayuda</div>
                    <div class="com_fc_items com_footer_scale-1px" @click="gotoPrivacy">
                        <div class="com_fc_ileft"><span>Aviso de Privacidad</span></div>
                        <div class="com_fc_iright">
                            <div class="com_fc_irview">
                                <img src="@/assets/mobimg/comf_rparrow.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="com_fc_items com_footer_scale-1px" @click="gotoProvision">
                        <div class="com_fc_ileft"><span>Términos y Condiciones</span></div>
                        <div class="com_fc_iright">
                            <div class="com_fc_irview">
                                <img src="@/assets/mobimg/comf_rparrow.png" alt="">
                            </div>
                        </div>
                    </div>

                    <div class="com_fc_middle_wrap">
                        <div class="com_fc_middle">
                            <div class="com_fc_mappicon">
                                <img src="@/assets/mobimg/comf_rpicon.png" alt="">
                            </div>
                            <div class="com_fc_mappname">Rapidayuda</div>
                            <div class="com_fc_mappdesc">
                                <span>INTERVALO FINANZAS, S.A. DE C.V. y su  aplicación RAPIDAYUDA</span>
                            </div>
                        </div>
                    </div>

                    <div class="com_fc_down_wrap" v-if="$store.state.common.pageIndexb">
                        <div class="com_fc_down">
                            <div class="com_fc_dview">
                                <img src="@/assets/mobimg/comf_appdload.png" alt="" @click="onAppLoad">
                                <div class="com_fc_dvclose" @click="onAppLoadClose">
                                    <img src="@/assets/mobimg/comf_rpclose.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>


            <van-popup v-model="loginshow" position="bottom" round>
                <div class="verifycode_wrap">
                    <div class="verifycode_cont">
                        <div class="verifycode_tilte"></div>
                        <div class="verifycode_top">
                            <div class="verifycode_tleft">
                                <span>
                                    Iniciar sesión registrarse
                                </span>
                            </div>
                            <div class="verifycode_tright" @click="onCloseLogin">
                                <img src="@/assets/mobimg/verifycode_tright.png" alt="">
                            </div>
                        </div>

                        <div class="verifycode_form">
                            <div class="verifycode_items verifycode_items_scale-1px">
                                <div class="verifycode_item">
                                    <div class="verifycode_ioneleft">+52</div>
                                    <div class="verifycode_ioneright">
                                        <input type="number" @input="onMobile" v-model="mobile" placeholder="Ingresa tu celular" >
                                    </div>
                                </div>
                            </div>
                            <div class="verifycode_items" :class="isWarn ? 'verifycode_iwarn_scale-1px' : 'verifycode_items_scale-1px'">
                                <div class="verifycode_item verifycode_itemtwo">
                                    <div class="verifycode_itwoleft">
                                        <input type="number" v-model="smscode" placeholder="Ingresa OTP" >
                                    </div>
                                    <div class="verifycode_itworight" @click="verificationCode" :class="isSendcode ? 'active' : '' " >
                                        <button id="sendcode" :class="isSendcode ? 'active' : '' " :disabled="isSendcode">{{codeval}}</button>
                                    </div>
                                </div>
                            </div>

                            <div class="verifycode_fwran_view">
                                <template v-if="isVerGoogtoken">
                                    Realiza la verificación de seguridad
                                </template>
                                <template v-if="varSmscode">
                                    Ingresa el código correcto
                                </template>
                            </div>
                        </div>

                        <div class="verifycode_google_wrap">
                            <div class="verifycode_google_cont" id="verifycode_google_cont">

                            </div>
                        </div>

                        <div class="verifycode_footer_btns" @click="goLogin">
                            <div class="verifycode_footer_btn">
                                <van-button round color="#00CA88" block style="height: 1.222222rem;">
                                    <span style="font-weight: bold;">Entregar</span>
                                </van-button>
                            </div>
                        </div>

                        <div class="verifycode_footer_xys">
                            <div class="verifycode_footer_xy">
                                Ver <span @click="gotoProvision">acuerdo de privacidad</span> y <span @click="gotoPrivacy">acuerdo de usuario</span>
                            </div>
                        </div>
                    </div>
                </div>
            </van-popup>


        </div>
    </div>
</template>

<script>

    let clock = null;

    import mobNav from "@/components/mobNav.vue";
    import scrollUpdown from "@/mixins/index";

    import { Toast } from 'vant';

    import { getSmscode, loginsms } from '@/api/login';

    import { setAccessToken, setIsRepeatLoan } from '@/utils/auth'

    import { loadingShow, loadingHide } from '@/utils/loading'

    import { loaninfo } from '@/api/loan';

    import axios from 'axios'

    export default {
        name: 'compoundeIndextob',
        components: { mobNav },
        mixins: [scrollUpdown],
        data () {
            return {
                activeNav: 'indextob',
                apiHead: 'https://api.rapidayuda.com/h5/',
                loginshow: false,
                nums: 60,
                codeval: 'Recibir OTP',
                isSendcode: false,
                isWarn: false,
                mobile: null,
                smscode: null,
                googleSucctoken: null,
                isVerGoogtoken: false,
                varSmscode: false,
            }
        },
        mounted() {
            window.addEventListener('scroll',this.handleScroll)
            this.googleSucctoken = null;

            this.$store.commit('common/SET_PAGE_INDEXA', true);
            this.$store.commit('common/SET_PAGE_INDEXB', true);
            this.$store.commit('common/SET_PAGE_TOKEN', true);
            this.$store.commit('common/SET_PAGE_EXAMINE', true);
            this.$store.commit('common/SET_PAGE_PAYMENT', true);
            this.$store.commit('common/SET_PAGE_NEW', true);

        },
        methods: {
            onAppLoadvv() {
                window.location.href = 'https://vivavida.onelink.me/v0MW/4nomodtd';
            },
            onAppLoad() {
                //console.log('下载app')
                window.location.href = 'https://www.rapidayuda.com/app/Rapidayuda_releasenext.apk';
            },
            onAppLoadClose(){
                this.$store.commit('common/SET_PAGE_INDEXB', false);
            },
            gotoPrivacy() {
                window.location.href = '/h5/privacy_agreement.html'
            },
            gotoProvision() {
                window.location.href = '/h5/provision.html'
            },
            handleScroll() {
                //console.log('页面滚动状态')
                //console.log(this.topNavShow)
            },
            gotoLogin() {
                //console.log('登录')
                this.loginshow = true;

                let _this = this;


                setTimeout(() => {
                    //
                    window.grecaptcha.render('verifycode_google_cont', {
                        'sitekey': '6LesI4QlAAAAAJuVTcvWAK0xS8bph2oH5kHsYLOn',
                        'theme': 'light',  //主题颜色，有light与dark两个值可选
                        'size': 'normal', //尺寸规则，有normal与compact两个值可选
                        'callback': _this.grecaptchaCallback, //验证成功回调
                        'expired-callback': _this.expiredCallback, //验证过期回调
                        'error-callback': _this.errorCallback, //验证错误回调
                    });
                }, 200);
            },
            grecaptchaCallback(token){
                //console.log(token)
                this.googleSucctoken = token;
                this.isWarn = false;
                this.isVerGoogtoken = false;
                //this.verificationCode();
            },
            expiredCallback(data) {
                //console.log(data)
                this.googleSucctoken = null;
            },
            errorCallback(data) {
                //console.log(data)
                this.googleSucctoken = null;
            },
            onCloseLogin() {
                this.loginshow = false;
            },
            doLoop() {
                this.nums--;
                if(this.nums > 0){
                    this.codeval = this.nums + 's';
                } else {
                    clearInterval(clock);
                    this.isSendcode = false;
                    this.codeval = 'Recibir OTP';
                    this.nums = 60;
                }
            },
            onMobile(){
                //console.log(this.mobile)
                let getmobile = this.mobile;

                let firstStr = getmobile.substr(0, 1);

                if(firstStr == 1){ //1开始 11位

                    if(getmobile.length > 11){
                        this.mobile = getmobile.substr(0, 11);
                    }

                } else {//非1 10位
                    if(getmobile.length > 10){
                        this.mobile = getmobile.substr(0, 10);
                    }
                }
            },
            verificationCode() {
                let _this = this;

                // Toast.loading({
                //     message: '请求中...',
                //     forbidClick: true,
                // });



                if(this.mobile){
                    //console.log(111111)
                    let getmobile = this.mobile;

                    let firstStr = getmobile.substr(0, 1);

                    let isflag = false;

                    if(firstStr == 1){ //1开始 11位
                        if(getmobile.length == 11){
                            isflag = true;
                        } else {
                            isflag = false;
                        }

                    } else {//非1 10位
                        if(getmobile.length == 10){
                            isflag = true;
                        } else {
                            isflag = false;
                        }
                    }

                    if(isflag){
                        if(this.googleSucctoken){
                            let usemobile = `+52${this.mobile}`;
                            let postData = {
                                "mobile": usemobile,
                                "source":"Rapidayuda",
                                "channel":"h501"
                            };
                            loadingShow();

                            // this.access_token = '1122445555'
                            // this.originLoanAmount = 700 //获取原始贷款金额
                            // this.loanPeriod = 7 //获取贷款期限
                            // this.loanEntrance = 2 //借款协议入口
                            // this.isReloan = 1 //首贷还是复贷0(首贷) 1（复贷）
                            // this.couponId = '234555666'  //优惠券ID
                            // this.productId = '344677888'  //产品ID
                            //
                            // axios.post(this.apiHead + 'v1/account/contract/get', {
                            //     access_token: this.access_token,
                            //     originLoanAmount: Number(this.originLoanAmount),
                            //     loanPeriod: Number(this.loanPeriod),
                            //     loanEntrance: Number(this.loanEntrance),
                            //     isReloan: Number(this.isReloan),
                            //     couponId: Number(this.couponId),
                            //     productId: Number(this.productId),
                            //     sysCode: "Rapidayuda"
                            // }).then(res => {
                            //     if (res.data.code == 0) {
                            //         this.data = res.data.data
                            //     }
                            // });

                            getSmscode(postData).then(res => {
                                //console.log(res)
                                Toast.clear();
                                loadingHide()
                                if(res.code == 0){
                                    _this.isWarn = false;
                                    _this.varSmscode = false;
                                    _this.isSendcode = true;
                                    _this.codeval = this.nums + 's';
                                    clock = setInterval(_this.doLoop, 1000);
                                } else {
                                    _this.isWarn = true;
                                    _this.varSmscode = true;
                                    Toast(res.message)
                                }
                            }, error => {
                                _this.isWarn = true;
                                _this.varSmscode = true;
                                loadingHide()
                            })
                            this.isWarn = false;
                            this.isVerGoogtoken = false;
                        } else {
                            this.isWarn = true;
                            this.isVerGoogtoken = true;
                        }

                    } else {
                        loadingHide()
                        Toast('Formato incorrecto del número móvil') //手机号码格式错误
                    }

                } else {
                    loadingHide()
                    Toast('Por favor ingrese el número de teléfono móvil') //请输入手机号
                }
            },
            getloanInfo(access_token, is_repeat_loan){ //获取订单状态
                let _this = this;
                loadingShow();
                let postData = {
                    access_token: access_token,
                    source: "Rapidayuda",
                    channel: "h501"
                };
                loaninfo(postData).then(res => {
                    //console.log(res)
                    //_this.$toast.clear();
                    loadingHide();
                    _this.loginshow = false;
                    if(res.code == 0){
                        let useresult = res.result;
                        if(useresult){
                            let { loan_info } = useresult;

                            let { order_status } = loan_info;

                            if(order_status == 7 || order_status == 9 || order_status == 11){ //还款
                                _this.$router.push({
                                    path: '/compoundloan/repayment'
                                });
                            } else if(order_status == 0 || order_status == 10) { //结清
                                if(is_repeat_loan){ //复贷
                                    _this.$router.push({
                                        path: '/compoundloan/indextoken'
                                    })
                                } else {
                                    //console.log('首贷用户')
                                    _this.$router.push({
                                        path: '/compoundloan/indexnew'
                                    })
                                }
                            } else if(order_status == 8){
                                _this.$router.push({
                                    path: '/compoundloan/indextoken'
                                })
                            } else {
                                _this.$router.push({
                                    path: '/compoundloan/examine'
                                });
                            }
                        }
                    }
                }, error => {
                    loadingHide()
                    _this.loginshow = false;
                })
            },
            goLogin() {
                let _this = this;
                this.isWarn = false;
                this.varSmscode = false;
                if(this.mobile){
                    let getmobile = this.mobile;

                    let firstStr = getmobile.substr(0, 1);

                    let isflag = false;

                    if(firstStr == 1){ //1开始 11位
                        if(getmobile.length == 11){
                            isflag = true;
                        } else {
                            isflag = false;
                        }

                    } else {//非1 10位
                        if(getmobile.length == 10){
                            isflag = true;
                        } else {
                            isflag = false;
                        }
                    }

                    //console.log(firstStr)

                    if(isflag){
                        let usemobile = `+52${this.mobile}`;
                        //let usemobile = '+5213311111113';

                        if(this.smscode){
                            if(this.googleSucctoken){
                                /*Toast.loading({
                                    message: '请求中...',
                                    forbidClick: true,
                                });*/
                                loadingShow()
                                let postData = {
                                    "mobile": usemobile,
                                    "source":"Rapidayuda",
                                    "channel":"h501",
                                    "auth_code": _this.smscode
                                };
                                loginsms(postData).then(res => {
                                    //loadingHide()
                                    //_this.loginshow = false;
                                    if(res.code == 0){
                                        //console.log(res)
                                        let useData = res.data;

                                        if(useData){
                                            let access_token = useData.access_token;
                                            setAccessToken(access_token);

                                            let is_repeat_loan = useData.is_repeat_loan;
                                            setIsRepeatLoan(is_repeat_loan);

                                            _this.$store.commit('common/SET_PAGE_TYPE', 'h502');

                                            _this.getloanInfo(access_token, is_repeat_loan);

                                            /*if(is_repeat_loan){ //复贷
                                                _this.$router.push({
                                                    path: '/compoundloan/indextoken'
                                                })
                                            } else {
                                                console.log('首贷用户')
                                                _this.$router.push({
                                                    path: '/compoundloan/indexnew'
                                                })
                                            }*/
                                        }

                                    } else {
                                        Toast(res.message)
                                    }
                                }, error => {
                                    if(error.code == 400116){
                                        _this.isWarn = true;
                                        _this.varSmscode = true;
                                    }
                                    loadingHide()
                                })
                            } else {
                                Toast('Por favor marque la verificación humana') //请勾选人机验证
                            }
                        } else {
                            Toast('Por favor introduzca el captcha') //请输入验证码
                        }
                    } else {
                        Toast('Formato incorrecto del número móvil') //手机号码格式错误
                    }

                } else {
                    Toast('Por favor ingrese el número de teléfono móvil') //请输入手机号
                }
            }
        }
    }
</script>

<style>
    .itob_warp{
        width: 100%;
        height: auto;
        background: #F6F6F6;
        box-sizing: border-box;
        padding: 0.888889rem 0.444444rem 1.111111rem 0.444444rem;
    }
    .itob_cont_top{
        width: 100%;
        height: 6.805556rem;
        background: #00CA88;
        border-radius: 0.444444rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-top: 1.027778rem;
        position: relative;
    }
    .itob_cont_tname{
        width: 100%;
        height: 0.972222rem;
        font-size: 0.527778rem;
        font-weight: 500;
        color: #FFFFFF;
        text-align: center;
    }
    .itob_cont_tnums{
        width: 100%;
        height: 2.2rem;
    }
    .itob_cont_tnum{
        width: 100%;
        height: 1.388889rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
    .itob_cont_tnum span{
        font-size: 1.1rem;
        font-weight: bold;
        color: #FFF7AB;
    }
    .itob_cont_tdesc{
        width: 100%;
        height: 0.83rem;
        font-size: 0.333333rem;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
    }
    .itob_cont_tbtns_wrap{
        width: 100%;
        height: 1.222222rem;
        position: absolute;
        left: 0;
        bottom: 0.555556rem;
    }
    .itob_cont_tbtns{
        width: 66.67%;
        height: 1.222222rem;
        margin: 0 auto;
        background: #ffffff;
        border-radius: 0.611111rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .itob_cont_tbtns span{
        font-size: 0.444444rem;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #00CA88;
    }
    .itob_ctimg01{
        width: 2.416667rem;
        height: 2.777778rem;
        position: absolute;
        top: 0;
        right: 0;
    }
    .itob_ctimg01 img{
        display: block;
        width: 100%;
    }
    .itob_ctimg02{
        width: 1.611111rem;
        height: 1.694444rem;
        position: absolute;
        left: -0.444444rem;
        top: 2.1rem;
    }
    .itob_ctimg03{
        width: 2.5rem;
        height: 2.444444rem;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    .itob_ctimg03 img{
        display: block;
        width: 100%;
    }
    .itob_ctimg04{
        width: 2.583333rem;
        height: 2.916667rem;
        position: absolute;
        right: -1.0rem;
        bottom: 0.222222rem;
    }
    .itob_ctimg04 img{
        display: block;
        width: 100%;
    }
    .imiddle_wrap{
        width: 100%;
        height: auto;
    }
    .imiddle_cont{
        width: 100%;
        height: 1.111111rem;
        position: relative;
    }
    .imiddle_cimg{
        width: 100%;
        height: 3.277778rem;
        position: absolute;
        left: 0;
        top: -1.638889rem;
    }
    .imiddle_cimg img{
        display: block;
        width: 100%;
    }
    .itob_downimg{
        width: 100%;
        height: auto;
    }
    .itob_downimg img{
        display: block;
        width: 100%;
    }
</style>
