<template>
    <div>
        <div class="mobile_wrap" v-if="topNavShow">
            <mobNav :activeNav="activeNav"></mobNav>
        </div>
        <div class="mainview_wrap">
            <div class="index_topwrap">
                <div class="index_topcont">
                    <div class="index_top01icon">
                        <img src="@/assets/mobimg/index_top01icon.png" alt="">
                    </div>
                    <div class="index_toptitle">
                        <p>Beneficio exclusivo</p>
                        <p>Monto y tasa de aprobación alta</p>
                    </div>
                    <div class="index_topmiddle">
                        <div class="index_topmiddle_cont">
                            $<span>{{ruleAmount}}</span>
                        </div>
                    </div>
                    <div class="index_toprule_wrap">
                        <div id='rulerWrapper'></div>
                        <div class="index_toprule_left">
                            <img src="@/assets/mobimg/toprule_left.png" alt="">
                        </div>
                        <div class="index_toprule_right">
                            <img src="@/assets/mobimg/toprule_right.png" alt="">
                        </div>
                        <div class="itoken_toprule_yind" v-if="ruleshow" @touchstart="onHiddenRule($event)" @touchmove="onHiddenRule($event)" @touchEnd="onHiddenRule($event)">
                            <div class="itoken_toprule_ydview">
                                <img src="@/assets/mobimg/rule_teach.gif" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="index_topbtns">
                        <span>Ver mi monto</span>
                    </div>
                </div>
            </div>

            <div class="index_middle_wrap" id="index_middle_wrap">
                <!--<div class="index_middle_bgimg">-->
                    <!--<img src="@/assets/mobimg/index_down_bgimg.png" alt="">-->
                <!--</div>-->
                <div class="index_middle_cont" id="index_middle_cont">
                    <div class="index_mcont_head" @click="gotoLogin">
                        <div class="index_mcont_hview">
                            <div class="index_mcont_hvimg">
                                <img src="@/assets/mobimg/index_dowm_01.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="index_mcont_one">
                        <img src="@/assets/mobimg/index_mcont_one.png" alt="">
                    </div>
                    <div class="index_mcont_two">
                        <img src="@/assets/mobimg/index_mcont_two.png" alt="">
                    </div>
                    <div class="index_mcont_thr">
                        <img src="@/assets/mobimg/index_mcont_thr.png" alt="">
                    </div>
                    <div class="index_mcont_fur">
                        <img src="@/assets/mobimg/index_mcont_fur.png" alt="">
                    </div>
                </div>
            </div>

            <div class="com_footer_wrap">
                <div class="com_footer_cont">
                    <div class="com_fc_title">Socios</div>
                    <div class="com_fc_items com_footer_scale-1px" @click="onAppLoadvv">
                        <div class="com_fc_ileft"><span>Viva Vida</span></div>
                        <div class="com_fc_iright">
                            <div class="com_fc_irview">
                                <img src="@/assets/mobimg/comf_rparrow.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="com_fc_title">Rapidayuda</div>
                    <div class="com_fc_items com_footer_scale-1px" @click="gotoPrivacy">
                        <div class="com_fc_ileft"><span>Aviso de Privacidad</span></div>
                        <div class="com_fc_iright">
                            <div class="com_fc_irview">
                                <img src="@/assets/mobimg/comf_rparrow.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="com_fc_items com_footer_scale-1px" @click="gotoProvision">
                        <div class="com_fc_ileft"><span>Términos y Condiciones</span></div>
                        <div class="com_fc_iright">
                            <div class="com_fc_irview">
                                <img src="@/assets/mobimg/comf_rparrow.png" alt="">
                            </div>
                        </div>
                    </div>

                    <div class="com_fc_middle_wrap">
                        <div class="com_fc_middle">
                            <div class="com_fc_mappicon">
                                <img src="@/assets/mobimg/comf_rpicon.png" alt="">
                            </div>
                            <div class="com_fc_mappname">Rapidayuda</div>
                            <div class="com_fc_mappdesc">
                                <span>INTERVALO FINANZAS, S.A. DE C.V. y su  aplicación RAPIDAYUDA</span>
                            </div>
                        </div>
                    </div>

                    <div class="com_fc_down_wrap" v-if="$store.state.common.pageIndexa">
                        <div class="com_fc_down">
                            <div class="com_fc_dview">
                                <img src="@/assets/mobimg/comf_appdload.png" alt="" @click="onAppLoad">
                                <div class="com_fc_dvclose" @click="onAppLoadClose">
                                    <img src="@/assets/mobimg/comf_rpclose.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <van-popup v-model="loginshow" position="bottom" round>
                <div class="verifycode_wrap">
                    <div class="verifycode_cont">
                        <div class="verifycode_tilte"></div>
                        <div class="verifycode_top">
                            <div class="verifycode_tleft">
                                <span>
                                    Iniciar sesión registrarse
                                </span>
                            </div>
                            <div class="verifycode_tright" @click="onCloseLogin">
                                <img src="@/assets/mobimg/verifycode_tright.png" alt="">
                            </div>
                        </div>

                        <div class="verifycode_form">
                            <div class="verifycode_items verifycode_items_scale-1px">
                                <div class="verifycode_item">
                                    <div class="verifycode_ioneleft">+52</div>
                                    <div class="verifycode_ioneright">
                                        <input type="number" @input="onMobile" v-model="mobile" placeholder="Ingresa tu celular" >
                                    </div>
                                </div>
                            </div>
                            <div class="verifycode_items" :class="isWarn ? 'verifycode_iwarn_scale-1px' : 'verifycode_items_scale-1px'">
                                <div class="verifycode_item verifycode_itemtwo"> <!--verifycode_itemtwo-->
                                    <div class="verifycode_itwoleft">
                                        <input type="number" v-model="smscode" placeholder="Ingresa OTP" >
                                    </div>
                                    <div class="verifycode_itworight" @click="verificationCode" :class="isSendcode ? 'active' : '' " >
                                        <button id="sendcode" :class="isSendcode ? 'active' : '' " :disabled="isSendcode">{{codeval}}</button>
                                    </div>
                                </div>
                            </div>

                            <div class="verifycode_fwran_view">
                                <template v-if="isVerGoogtoken">
                                    Realiza la verificación de seguridad
                                </template>
                                <template v-if="varSmscode">
                                    Ingresa el código correcto
                                </template>
                            </div>
                        </div>

                        <div class="verifycode_google_wrap">
                            <!--style="transform: scale(0.87); -webkit-transform: scale(0.87); transform-origin: 0 0; -webkit-transform-origin: 0 0;"-->
                            <!---->
                            <div class="verifycode_google_cont" id="verifycode_google_cont">

                            </div>
                        </div>

                        <div class="verifycode_footer_btns">
                            <div class="verifycode_footer_btn">
                                <van-button round color="#00CA88" block @click="goLogin">
                                    <span style="font-weight: bold;">Entregar</span>
                                </van-button>
                            </div>
                        </div>

                        <div class="verifycode_footer_xys">
                            <div class="verifycode_footer_xy">
                                Ver <span @click="gotoProvision">acuerdo de privacidad</span> y <span @click="gotoPrivacy">acuerdo de usuario</span>
                            </div>
                        </div>
                    </div>
                </div>
            </van-popup>
        </div>
    </div>
</template>



<script>

    let clock = null;

    import $ from 'jquery';

    function MeasureRuler(dParam){
        var param={
            wrapperId:"rulerWrapper",   //标尺ID
            max:1000,      //标尺最大刻度
            minUnit:1,     //标尺最小单位刻度
            unitSet:10,    //标尺单位单位刻度组
            min:0,         //最小值
            mult:1         //标尺单位刻度的倍数 最小是1 即每一刻度占10px
        }
        $.extend(param,dParam);

        var limitLeft=0;
        var scrollerWidth=0;
        var wrapperWidth=0;
        var pleft=0;
        var firstRand=true;
        var self=this;
        var fixedLen=param.minUnit.toString().split(".")[1]?param.minUnit.toString().split(".")[1].length:0;
        this.initRuler=function(){
            if(param.min>param.max) param.min=0
            var nhtml="";
            limitLeft=0;
            scrollerWidth=0;
            wrapperWidth=0;
            $("#"+param.wrapperId).addClass("rulerWrapper")
            $("#"+param.wrapperId).append('<div class="rulerScroller"></div><div class="rulerPointer"><div style="width: 100%;height: 100%; position: relative;"><div class="rulerPointerSolid"></div></div></div>')
            var setLen=Math.ceil((param.max-param.min)/(param.unitSet*param.minUnit)) + 9;
            var setWidth=param.unitSet*10*param.mult;
            for(var i=0;i<setLen;i++){
                nhtml+="<div class='sizeNo' style='left:"+(i*setWidth-setWidth/2)+"px;width:"+setWidth+"px'><span class='sizeNum'></span></div><ul  style='width:"+setWidth+"px'>";
                for(var j=0;j<(param.unitSet-1);j++){
                    nhtml+="<li style='width:"+10*param.mult+"px'></li>";
                }
                nhtml+="</ul>";
            }
            $("#"+param.wrapperId+" .rulerScroller").append(nhtml);
            var st;
            wrapperWidth=$("#"+param.wrapperId).width();
            scrollerWidth=setLen*10*param.unitSet*param.mult;

            $("#"+param.wrapperId).on('touchstart',function(e){
                var touch = e.originalEvent.targetTouches[0];
                st = touch.pageX;
            })

            $("#"+param.wrapperId).on('touchmove',function(e){
                var touch = e.originalEvent.targetTouches[0];
                var x = touch.pageX;
                var lf=$("#"+param.wrapperId+" .rulerScroller").position().left;
                var nlf=lf+x-st;

                //超过最左或者最右标尺不可滑动
                if(lf-50 > limitLeft || wrapperWidth-scrollerWidth>lf+50){
                    return;
                }
                var pointerVal=Math.floor((limitLeft-nlf)/(10*param.mult))>0?Math.floor((limitLeft-nlf)/(10*param.mult)):0;
                pointerVal=param.minUnit*pointerVal+param.min;

                if(fixedLen) pointerVal=pointerVal.toFixed(fixedLen);
                if(param.callback)  param.callback(pointerVal)
                $("#"+param.wrapperId+" .rulerScroller").css("left",nlf);

            })

            $("#"+param.wrapperId).on('touchend',function(){

                var lf=$("#"+param.wrapperId+" .rulerScroller").position().left;
                if(lf > limitLeft){
                    $("#"+param.wrapperId+" .rulerScroller").css("left",limitLeft);
                    if(param.callback) param.callback(param.min);
                    return;
                }else if(wrapperWidth-scrollerWidth>lf){
                    self.setValue(param.max);
                    return;
                }
                //var lf=$("#"+param.wrapperId+" .rulerScroller").position().left;

                var disNo=Math.round((lf-pleft)/(10*param.mult));
                var nDis=disNo*10*param.mult+pleft;
                $("#"+param.wrapperId+" .rulerScroller").css("left",nDis);
                var pointerVal=Math.floor((limitLeft-nDis)/(10*param.mult));
                pointerVal=param.minUnit*pointerVal+param.min;
                if(fixedLen) pointerVal=pointerVal.toFixed(fixedLen);
                if(param.callback)  param.callback(pointerVal)
                //console.log(pointerVal)
            })

            //中间标志的位置
            if(firstRand){
                pleft=$("#"+param.wrapperId+" .rulerPointer").position().left;
            }
            var rulerLNo=0;
            for(var z=1;z<setLen;z++){
                //console.log(setLen)
                if(z*setWidth>pleft) {
                    limitLeft=pleft-z*setWidth;
                    rulerLNo=z;
                    break;
                }
            }

            //标尺初始化数值
            this.setValue(param.value);

            //标尺刻度值
            $("#"+param.wrapperId+" .sizeNum").each(function(idx,ele){
                //console.log(idx)
                if(idx>=rulerLNo && idx<setLen-5){
                    var iv=(idx-rulerLNo)*param.minUnit*param.unitSet+param.min;
                    if(fixedLen) iv=iv.toFixed(fixedLen);
                    $(ele).html(iv);
                }
            })
        }
        this.setValue=function(val){
            var nval=val?val:param.min;
            if(nval>param.max) nval=param.max;
            if(nval<param.min) nval=param.min;

            var nlval=nval-param.min;

            $("#"+param.wrapperId+" .rulerScroller").css("left",limitLeft-nlval*10*param.mult/param.minUnit);
            if(param.callback)  param.callback(nval)
        };

        this.reDrawRuler=function(nParam){
            nParam.value=nParam.value?nParam.value:0;
            firstRand=false;
            if(nParam){
                $.extend(param,nParam)
            }

            fixedLen=param.minUnit.toString().split(".")[1]?param.minUnit.toString().split(".")[1].length:0;
            $("#"+param.wrapperId).html("");
            $("#"+param.wrapperId).unbind('touchstart');
            $("#"+param.wrapperId).unbind('touchend');
            $("#"+param.wrapperId).unbind('touchmove');
            this.initRuler();
        };

        this.initRuler();

    }


    import mobNav from "@/components/mobNav.vue";
    import scrollUpdown from "@/mixins/index";
    import { Toast } from 'vant';

    import { getSmscode, loginsms } from '@/api/login';

    import { setAccessToken, setIsRepeatLoan } from '@/utils/auth'

    import { uniqueKey } from '@/utils/web-sdk-release-data-1.0.0'

    import { loadingShow, loadingHide } from '@/utils/loading'

    import { loaninfo } from '@/api/loan';


    export default {
        name: 'compoundeIndex',
        components: { mobNav },
        mixins: [scrollUpdown],
        data () {
            return {
                activeNav: 'index',
                ruleshow: true,
                ruleAmount: 20000,
                loginshow: false,
                nums: 60,
                codeval: 'Recibir OTP',
                isSendcode: false,
                isWarn: false,
                mobile: null,
                smscode: null,
                googleSucctoken: null,
                isVerGoogtoken: false,
                varSmscode: false,
            }
        },
        mounted() {
            window.addEventListener('scroll',this.handleScroll)
            //初始化尺子
            let _this = this;
            new MeasureRuler({
                wrapperId:"rulerWrapper",     //容器ID，页面中写一个DIV就行 (必须)
                max:50000,                     //刻度尺最大的刻度    (非必须，默认为1000)
                minUnit:50,                    //刻度尺最小刻度    (非必须，默认为1)
                unitSet:5,                   //刻度尺单元长度    (非必须，默认是10)
                value: 20000,                      //初始化数值       (非必须，默认为1)
                mult:1,                       //刻度值倍数，默认是最小刻度值为10px，如果定mult为3则最小刻度为30px (非必须，默认为1)
                callback:_this.rulerSetValue,       //滑动尺子过程中的回调函数     (非必须)
                min:300                    //刻度尺最小刻度
            });

            this.googleSucctoken = null;

            this.$store.commit('common/SET_PAGE_INDEXA', true);
            this.$store.commit('common/SET_PAGE_INDEXB', true);
            this.$store.commit('common/SET_PAGE_TOKEN', true);
            this.$store.commit('common/SET_PAGE_EXAMINE', true);
            this.$store.commit('common/SET_PAGE_PAYMENT', true);
            this.$store.commit('common/SET_PAGE_NEW', true);
        },
        methods: {
            onAppLoadvv() {
                window.location.href = 'https://vivavida.onelink.me/v0MW/4nomodtd';
            },
            onAppLoad() {
                //console.log('下载app')
                window.location.href = 'https://www.rapidayuda.com/app/Rapidayuda_releasenext.apk';
            },
            onAppLoadClose(){
                this.$store.commit('common/SET_PAGE_INDEXA', false);
            },
            onHiddenRule() {
                this.ruleshow = false;
            },
            gotoPrivacy() {
                window.location.href = '/h5/privacy_agreement.html'
            },
            gotoProvision() {
                window.location.href = '/h5/provision.html'
            },
            handleScroll() {
                //console.log('页面滚动状态')
                //console.log(this.topNavShow)
            },
            rulerSetValue(val){
                //console.log(val)
                this.ruleAmount = val;
            },
            gotoLogin() {
                console.log('登录')
                this.loginshow = true;

                let _this = this;

                setTimeout(() => {
                    //
                    window.grecaptcha.render('verifycode_google_cont', {
                        'sitekey': '6LesI4QlAAAAAJuVTcvWAK0xS8bph2oH5kHsYLOn',
                        'theme': 'light',  //主题颜色，有light与dark两个值可选
                        'size': 'normal', //尺寸规则，有normal与compact两个值可选
                        'callback': _this.grecaptchaCallback, //验证成功回调
                        'expired-callback': _this.expiredCallback, //验证过期回调
                        'error-callback': _this.errorCallback, //验证错误回调
                    });

                }, 200);
            },
            grecaptchaCallback(token){
                //console.log(token)
                this.googleSucctoken = token;
                this.isWarn = false;
                this.isVerGoogtoken = false;
                //this.verificationCode();
            },
            expiredCallback(data) {
                //console.log(data)
                this.googleSucctoken = null;
            },
            errorCallback(data) {
                //console.log(data)
                this.googleSucctoken = null;
            },
            onCloseLogin() {
                this.loginshow = false;
            },
            doLoop() {
                this.nums--;
                if(this.nums > 0){
                    this.codeval = this.nums + 's';
                } else {
                    clearInterval(clock);
                    this.isSendcode = false;
                    this.codeval = 'Recibir OTP';
                    this.nums = 60;
                }
            },
            onMobile(){
                //console.log(this.mobile)
                let getmobile = this.mobile;

                let firstStr = getmobile.substr(0, 1);

                if(firstStr == 1){ //1开始 11位

                    if(getmobile.length > 11){
                        this.mobile = getmobile.substr(0, 11);
                    }

                } else {//非1 10位
                    if(getmobile.length > 10){
                        this.mobile = getmobile.substr(0, 10);
                    }
                }
            },
            verificationCode() {
                let _this = this;

                //5213311111112
                // Toast.loading({
                //     message: '请求中...',
                //     forbidClick: true,
                // });

                if(this.mobile){
                    //console.log(111111)
                    let getmobile = this.mobile;

                    let firstStr = getmobile.substr(0, 1);

                    let isflag = false;

                    if(firstStr == 1){ //1开始 11位
                        if(getmobile.length == 11){
                            isflag = true;
                        } else {
                            isflag = false;
                        }

                    } else {//非1 10位
                        if(getmobile.length == 10){
                            isflag = true;
                        } else {
                            isflag = false;
                        }
                    }

                    if(isflag){
                        if(this.googleSucctoken){
                            let usemobile = `+52${this.mobile}`;
                            let postData = {
                                "mobile": usemobile,
                                "source":"Rapidayuda",
                                "channel":"h501"
                            };
                            loadingShow();
                            getSmscode(postData).then(res => {
                                //console.log(res)
                                Toast.clear();
                                loadingHide()
                                if(res.code == 0){
                                    _this.isWarn = false;
                                    _this.varSmscode = false;
                                    _this.isSendcode = true;
                                    _this.codeval = this.nums + 's';
                                    clock = setInterval(_this.doLoop, 1000);
                                } else {
                                    _this.isWarn = true;
                                    _this.varSmscode = true;
                                    Toast(res.message)
                                }
                            }, error => {
                                _this.isWarn = true;
                                _this.varSmscode = true;
                                loadingHide()
                            });
                            this.isWarn = false;
                            this.isVerGoogtoken = false;
                        } else {
                            this.isWarn = true;
                            this.isVerGoogtoken = true;
                        }

                    } else {
                        loadingHide()
                        Toast('Formato incorrecto del número móvil') //手机号码格式错误
                    }

                } else {
                    loadingHide()
                    Toast('Por favor ingrese el número de teléfono móvil') //请输入手机号
                }
            },
            getloanInfo(access_token, is_repeat_loan){ //获取订单状态
                let _this = this;
                loadingShow();
                let postData = {
                    access_token: access_token,
                    source: "Rapidayuda",
                    channel: "h501"
                };
                loaninfo(postData).then(res => {
                    //console.log(res)
                    //_this.$toast.clear();
                    loadingHide();
                    _this.loginshow = false;
                    if(res.code == 0){
                        let useresult = res.result;
                        if(useresult){
                            let { loan_info } = useresult;

                            let { order_status } = loan_info;

                            if(order_status == 7 || order_status == 9 || order_status == 11){ //还款
                                _this.$router.push({
                                    path: '/compoundloan/repayment'
                                });
                            } else if(order_status == 0 || order_status == 10) { //结清
                                if(is_repeat_loan){ //复贷
                                    _this.$router.push({
                                        path: '/compoundloan/indextoken'
                                    })
                                } else {
                                    //console.log('首贷用户')
                                    _this.$router.push({
                                        path: '/compoundloan/indexnew'
                                    })
                                }
                            } else if(order_status == 8){
                                _this.$router.push({
                                    path: '/compoundloan/indextoken'
                                })
                            } else {
                                _this.$router.push({
                                    path: '/compoundloan/examine'
                                });
                            }
                        }
                    }
                }, error => {
                    loadingHide()
                    _this.loginshow = false;
                })

            },
            goLogin() {
                let _this = this;
                this.isWarn = false;
                this.varSmscode = false;
                if(this.mobile){
                    let getmobile = this.mobile;

                    let firstStr = getmobile.substr(0, 1);

                    let isflag = false;

                    if(firstStr == 1){ //1开始 11位
                        if(getmobile.length == 11){
                            isflag = true;
                        } else {
                            isflag = false;
                        }

                    } else {//非1 10位
                        if(getmobile.length == 10){
                            isflag = true;
                        } else {
                            isflag = false;
                        }
                    }

                    //console.log(firstStr)

                    if(isflag){
                        let usemobile = `+52${this.mobile}`;
                        //let usemobile = '+5213311111113';

                        if(this.smscode){
                            //this.googleSucctoken = true;
                            if(this.googleSucctoken){
                                /*Toast.loading({
                                    message: '请求中...',
                                    forbidClick: true,
                                });*/
                                loadingShow()
                                let postData = {
                                    "mobile": usemobile,
                                    "source":"Rapidayuda",
                                    "channel":"h501",
                                    "auth_code": _this.smscode
                                };
                                loginsms(postData).then(res => {
                                    //loadingHide()
                                    if(res.code == 0){
                                        //console.log(res)
                                        let useData = res.data;

                                        if(useData){
                                            let access_token = useData.access_token;
                                            setAccessToken(access_token);

                                            let is_repeat_loan = useData.is_repeat_loan;
                                            setIsRepeatLoan(is_repeat_loan);

                                            _this.$store.commit('common/SET_PAGE_TYPE', 'h501');

                                            _this.getloanInfo(access_token, is_repeat_loan);

                                            /*if(is_repeat_loan){ //复贷
                                                _this.$router.push({
                                                    path: '/compoundloan/indextoken'
                                                })
                                            } else {
                                                console.log('首贷用户')
                                                _this.$router.push({
                                                    path: '/compoundloan/indexnew'
                                                })
                                            }*/
                                        } else {
                                            loadingHide()
                                        }

                                    } else {
                                        loadingHide()
                                        Toast(res.message)
                                    }
                                }, error => {
                                    if(error.code == 400116){
                                        _this.isWarn = true;
                                        _this.varSmscode = true;
                                    }
                                    loadingHide()
                                })
                            } else {
                                Toast('Por favor marque la verificación humana') //请勾选人机验证
                            }
                        } else {
                            Toast('Por favor introduzca el captcha') //请输入验证码
                        }
                    } else {
                        Toast('Formato incorrecto del número móvil') //手机号码格式错误
                    }

                } else {
                    Toast('Por favor ingrese el número de teléfono móvil') //请输入手机号
                }
            }

        }
    }
</script>

<style>
    /* 1px边框 底部*/
    .com_footer_scale-1px{
        position: relative;
        border:none;
    }
    .com_footer_scale-1px:after{
        content: '';
        position: absolute;
        bottom: 0;
        background: #D4D4D4;
        width: 100%;
        height: 1px;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
    }
    .com_footer_wrap{
        width: 100%;
        height: auto;
        background: #EDEDED;
        box-sizing: border-box;
        padding: 0 0.444444rem 0.444444rem 0.444444rem;
    }
    .com_footer_cont{
        width: 100%;
        height: auto;
    }
    .com_fc_title{
        width: 100%;
        height: 1.805556rem;
        box-sizing: border-box;
        padding: 1.111111rem 0 0.25rem 0;
        font-size: 0.444444rem;
        font-weight: 500;
        color: #1A1A1A;
        line-height: 0.444444rem;
    }
    .com_fc_items{
        width: 100%;
        height: 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .com_fc_ileft{
        width: auto;
        height: 1.5rem;
        font-size: 0.388889rem;
        font-weight: 400;
        color: #808080;
        display: flex;
        align-items: center;
    }
    .com_fc_iright{
        width: 0.277778rem;
        height: 1.5rem;
        display: flex;
        align-items: center;
    }
    .com_fc_irview{
        width: 0.277778rem;
        height: 0.388889rem;
    }
    .com_fc_irview img{
        display: block;
        width: 100%;
    }
    .com_fc_middle_wrap{
        width: 100%;
        height: auto;
    }
    .com_fc_middle{
        width: 100%;
        height: auto;
        padding: 0.888889rem 0 1.055556rem 0;
    }
    .com_fc_mappicon{
        width: 0.805556rem;
        height: 0.805556rem;
        margin: 0 auto;
    }
    .com_fc_mappicon img{
        display: block;
        width: 100%;
    }
    .com_fc_mappname{
        width: 100%;
        height: 0.75rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0.138889rem 0 0.277778rem 0;
        font-size: 0.333333rem;
        font-weight: 400;
        color: #1A1A1A;
        text-align: center;
    }
    .com_fc_mappdesc{
        width: 100%;
        height: auto;
        line-height: 0.277778rem;
        text-align: center;
        padding-bottom: 0.777778rem;
    }
    .com_fc_mappdesc span{
        font-size: 12px;
        transform: scale(0.83);
        font-weight: 400;
        color: #808080;
    }
    .com_fc_down_wrap{
        width: 100%;
        height: 1.5rem;
    }
    .com_fc_down{
        width: 100%;
        height: auto;
        position: fixed;
        left: 0;
        bottom: 0.444444rem;
    }
    .com_fc_dview{
        width: 91.11%;
        height: 2.138889rem;
        margin: 0 auto;
        position: relative;
    }
    .com_fc_dview img{
        display: block;
        width: 100%;
        height: 100%;
    }
    .com_fc_dvclose{
        width: 0.611111rem;
        height: 0.611111rem;
        position: absolute;
        top: -0.194444rem;
        right: -0.194444rem;
    }





    .rulerWrapper .rulerPointer{
        width: 2px;
        height: 100%;
        position: absolute;
        left: 50%;
    }
    .rulerWrapper .rulerPointerSolid{
        width: 1px;
        height: 100%;
        background: #FFF7AB;
        position: absolute;
        left: -1px;
    }

    .rulerWrapper{
        height: 47px;
        overflow: hidden;
        position: relative;
        box-sizing: border-box;
        border-bottom: 1px solid #B5FFDF;

    }
    .rulerWrapper .rulerScroller{
        position: absolute;
        display: -webkit-flex;
        display: -webkit-box;
        display: flex;
        left:-10px;
        top: 22px;
        padding-bottom: 1px;
    }

    .rulerWrapper .rulerScroller ul{
        margin:0;
        padding:0;
        box-sizing:border-box;
        padding:0;
        width:100px;
        height: 0.58rem;;
        float:left;
        border-right: 1px solid #B5FFDF;
        position: relative;
        padding-top: 0.37rem;

    }
    .rulerWrapper .rulerScroller li{
        margin:0;
        padding:0;
        box-sizing:border-box;
        list-style: none;
        width: 10px;
        float: left;
        border-right:1px solid #B5FFDF;
        height: 8px;
    }

    .rulerWrapper .doTime span{
        margin-left:-15px;
    }

    .rulerWrapper .sizeNo{
        position:absolute;
        color: #eee;
        top:-16px;
        display: block;
        text-align:center;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .rulerWrapper .sizeNum{
        font-size: 12px;
        transform: scale(0.83);
    }

    .mainview_wrap{
        width: 100%;
        padding-top: 1.777778rem;
        background: #F6F6F6;
        overflow-x: hidden;
    }
    .index_topwrap{
        width: 100%;
        height: auto;
        box-sizing: border-box;
        padding: 0.888889rem 0.444444rem 0 0.444444rem;
        background: #F6F6F6;
    }
    .index_topcont{
        width: 100%;
        height: 8.666667rem;
        background: #00CA88;
        border-radius: 0.444444rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-top: 0.555556rem;
        position: relative;
    }
    .index_top01icon{
        width: 0.666667rem;
        height: 0.666667rem;
        margin: 0 auto;
    }
    .index_top01icon img{
        display: block;
        width: 100%;
    }
    .index_toptitle{
        width: 100%;
        height: auto;
        margin: 0 auto;
        font-size: 0.51rem;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 0.666667rem;
        padding-top: 0.111111rem;
    }
    .index_toptitle p{
        text-align: center;
    }
    .index_topmiddle{
        width: 100%;
        height: 1.25rem;
        margin-top: 0.611111rem;
        display: flex;
        justify-content: center;
    }
    .index_topmiddle_cont{
        display: inline-block;
        width: auto;
        height: 1.25rem;
        font-size: 1.111111rem;
        font-weight: bold;
        color: #FFF7AB;
        line-height: 1.111111rem;
        border-bottom: 1px dashed #B5FFDF;
    }
    .index_toprule_wrap{
        width: 100%;
        height: 2.388889rem;
        position: relative;
    }
    .index_toprule_left{
        width: 1.388889rem;
        height: 2.694444rem;
        position: absolute;
        left: 0;
        top: 0;
    }
    .index_toprule_left img{
        display: block;
        width: 100%;
        height: 2.694444rem;
    }
    .index_toprule_right{
        width: 1.388889rem;
        height: 2.694444rem;
        position: absolute;
        right: 0;
        top: 0;
    }
    .index_toprule_right img{
        display: block;
        width: 100%;
        height: 2.694444rem;
    }
    .index_topbtns{
        width: 66.67%;
        height: 1.222222rem;
        margin: 0 auto;
        background: #ffffff;
        border-radius: 0.611111rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .index_topbtns span{
        font-size: 0.444444rem;
        font-weight: 500;
        color: #00CA88;
    }
    .index_middle_wrap{
        width: 100%;
        height: auto;
        position: relative;
        background: #F6F6F6 url(../../assets/mobimg/index_down_bgimg.png) no-repeat top right;
        background-size: 100%;
        padding-bottom: 1.111111rem;
    }
    .index_middle_bgimg{
        width: 100%;
    }
    .index_middle_bgimg img{
        display: block;
        width: 100%;
        height: auto;
    }
    .index_middle_cont{
        width: 100%;
        height: auto;
    }
    .index_mcont_head{
        width: 100%;
        height: 1.944444rem;
    }
    .index_mcont_hview{
        width: 89.17%;
        height: 1.638889rem;
        margin: 0 auto;
        position: relative;
    }
    .index_mcont_hvimg{
        width: 100%;
        height: 3.277778rem;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    .index_mcont_hvimg img{
        display: block;
        width: 100%;
    }
    .index_mcont_one{
        width: 100%;
        height: auto;
        padding-bottom: 1.944444rem;
    }
    .index_mcont_one img{
        display: block;
        width: 100%;
    }
    .index_mcont_two{
        width: 100%;
        height: auto;
    }
    .index_mcont_two img{
        display: block;
        width: 100%;
    }
    .index_mcont_thr{
        width: 100%;
        height: auto;
    }
    .index_mcont_thr img{
        display: block;
        width: 100%;
    }
    .index_mcont_fur{
        width: 100%;
        height: auto;
    }
    .index_mcont_fur img{
        width: 100%;
        height: auto;
    }

    .itob_warp{
        width: 100%;
        height: auto;
        background: #F6F6F6;
        box-sizing: border-box;
        padding: 0.888889rem 0.444444rem 1.111111rem 0.444444rem;
    }
    .itob_cont_top{
        width: 100%;
        height: 6.805556rem;
        background: #00CA88;
        border-radius: 0.444444rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-top: 1.027778rem;
        position: relative;
    }
    .itob_cont_tname{
        width: 100%;
        height: 0.972222rem;
        font-size: 0.527778rem;
        font-weight: 500;
        color: #FFFFFF;
        text-align: center;
    }
    .itob_cont_tnums{
        width: 100%;
        height: 2.2rem;
    }
    .itob_cont_tnum{
        width: 100%;
        height: 1.388889rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
    .itob_cont_tnum span{
        font-size: 1.1rem;
        font-weight: bold;
        color: #FFF7AB;
    }
    .itob_cont_tdesc{
        width: 100%;
        height: 0.83rem;
        font-size: 0.333333rem;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
    }
    .itob_cont_tbtns_wrap{
        width: 100%;
        height: 1.222222rem;
        position: absolute;
        left: 0;
        bottom: 0.555556rem;
    }
    .itob_cont_tbtns{
        width: 66.67%;
        height: 1.222222rem;
        margin: 0 auto;
        background: #ffffff;
        border-radius: 0.611111rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .itob_cont_tbtns span{
        font-size: 0.444444rem;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #00CA88;
    }
    .itob_ctimg01{
        width: 2.416667rem;
        height: 2.777778rem;
        position: absolute;
        top: 0;
        right: 0;
    }
    .itob_ctimg01 img{
        display: block;
        width: 100%;
    }
    .itob_ctimg02{
        width: auto;
    }
    .itob_ctimg03{
        width: 2.5rem;
        height: 2.444444rem;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    .itob_ctimg03 img{
        display: block;
        width: 100%;
    }
    .itob_ctimg04{
        width: 2.583333rem;
        height: 2.916667rem;
        position: absolute;
        right: -1.0rem;
        bottom: 0.222222rem;
    }
    .itob_ctimg04 img{
        display: block;
        width: 100%;
    }
    .imiddle_wrap{
        width: 100%;
        height: auto;
    }
    .imiddle_cont{
        width: 100%;
        height: 1.111111rem;
        position: relative;
    }
    .imiddle_cimg{
        width: 100%;
        height: 3.277778rem;
        position: absolute;
        left: 0;
        top: -1.638889rem;
    }
    .imiddle_cimg img{
        display: block;
        width: 100%;
    }
    .itob_downimg{
        width: 100%;
        height: auto;
    }
    .itob_downimg img{
        display: block;
        width: 100%;
    }


    .verifycode_wrap{
        width: 100%;
        height: auto;
    }
    .verifycode_cont{
        width: 100%;
        height: auto;
        background: #FFFFFF;
        border-radius: 0.555556rem 0.555556rem 0px 0px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-top: 0.222222rem;
    }
    .verifycode_tilte{
        width: 1.25rem;
        height: 0.083333rem;
        background: #CFCFCF;
        border-radius: 0.083333rem;
        margin: 0 auto;
    }
    .verifycode_top{
        width: 100%;
        height: 2.583333rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0.555556rem 0.444444rem 0 0.444444rem;
        display: flex;
        justify-content: space-between;
    }
    .verifycode_tleft{
        width: auto;
        height: 0.611111rem;
        font-size: 0.527778rem;
        font-weight: bold;
        color: #1A1A1A;
        line-height: 44px;
        display: flex;
        align-items: center;
    }
    .verifycode_tleft span{
        float: left
    }
    .verifycode_tright{
        width: 0.666667rem;
        height: 0.666667rem;
    }
    .verifycode_tright img{
        display: block;
        width: 100%;
    }
    .verifycode_form{
        width: 91.11%;
        height: 5.972222rem;
        margin: 0 auto;
    }
    .verifycode_items_scale-1px{
        position: relative;
        border:none;
    }
    .verifycode_items_scale-1px:after{
        content: '';
        position: absolute;
        bottom: 0;
        background: #E7E7E7;
        width: 100%;
        height: 1px;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
    }
    .verifycode_items{
        width: 100%;
        height: 1.444444rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-top: 0.277778rem;
    }
    .verifycode_item{
        width: 100%;
        height: 1.166667rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .verifycode_ioneleft{
        width: 1.055556rem;
        height: 1.166667rem;
        font-size: 0.388889rem;
        font-weight: 400;
        color: #1A1A1A;
        line-height: 1.166667rem;
    }
    .verifycode_ioneright{
        width: 7.777778rem;
        height: 1.166667rem;
    }
    .verifycode_ioneright input{
        display: block;
        width: 100%;
        height: 1.12rem;
        outline: none;
        border: none;
        font-size: 0.388889rem;
        font-weight: 400;
        color: #1A1A1A;
        background: #ffffff;
        padding: 0;
    }
    input::-webkit-input-placeholder {
        /* WebKit browsers，webkit内核浏览器 */
        color: #CFCFCF;
        font-size: 12px;
    }
    input:-moz-placeholder {
        color: #CFCFCF;
        font-size: 12px;
    }
    input::-moz-placeholder {
        color: #CFCFCF;
        font-size: 12px;
    }
    input:-ms-input-placeholder {
        color: #CFCFCF;
        font-size: 12px;
    }
    .verifycode_itemtwo{
        justify-content: space-between;
    }
    .verifycode_itwoleft{
        width: 4.0rem;
        height: 1.166667rem;
    }
    .verifycode_itwoleft input{
        display: block;
        width: 100%;
        height: 1.1rem;
        outline: none;
        border: none;
        font-size: 0.388889rem;
        font-weight: 500;
        color: #1A1A1A;
        background: #ffffff;
        padding-left: 0;
    }
    .verifycode_itworight{
        width: auto;
        height: 0.75rem;
        background: #00CA88;
        border-radius: 0.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        padding: 0 0.13rem;
    }
    .verifycode_itworight.active{
        background: #CFCFCF;
    }
    .verifycode_itworight #sendcode{
        width: auto;
        min-width: 2.4rem;
        display: block;
        height: auto;
        font-size: 12px;
        font-weight: 400;
        color: #FFFFFF;
        outline: none;
        border: none;
        margin: 0 auto;
        background: #00CA88;
    }
    .verifycode_itworight #sendcode.active{
        background: #CFCFCF;
    }
    .verifycode_iwarn_scale-1px{
        position: relative;
        border:none;
    }
    .verifycode_iwarn_scale-1px:after{
        content: '';
        position: absolute;
        bottom: 0;
        background: #DB861F;
        width: 100%;
        height: 1px;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
    }
    .verifycode_fwran_view{
        width: 100%;
        height: auto;
        padding-top: 0.388889rem;
        font-size: 0.388889rem;
        font-weight: 500;
        color: #DB861F;
    }
    .verifycode_google_wrap{
        width: 100%;
        height: 2.388889rem;
        display: flex;
        justify-content: center;
    }
    .verifycode_google_cont{
        width: 7.305556rem;
        height: 1.944444rem;
        margin: 0 auto;
    }
    .verifycode_footer_btns{
        width: 100%;
        height: 1.666667rem;
    }
    .verifycode_footer_btn{
        width: 91.11%;
        height: 1.222222rem;
        margin: auto;
    }
    .verifycode_footer_xys{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        padding-bottom: 0.555556rem;
    }
    .verifycode_footer_xy{
        font-size: 14px;
        transform: scale(0.79);
        font-weight: 400;
        color: #00CA88;
    }
    .verifycode_footer_xy span{
        text-decoration: underline;
    }


    .hide{
        display: none!important;
    }
    .potato_loading{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9999999;
    }
    .potato_loading_zhe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(0,0,0,0.6);
        opacity: 0;
        transition: opacity 0.4s ease;
    }
    .potato_loading_zhe_tr{
        opacity: 1!important;
    }
    .potato_loading1{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.4s ease;
    }
    .potato_loading1_Icon {
        width: 40px;
        height: 40px;
        margin: 0;
        background: transparent;
        border-top: 3px solid #FFFFFF;
        border-right: 3px solid transparent;
        border-radius: 50%;
        -webkit-animation: 1s spin linear infinite;
        animation: 1s spin linear infinite;
    }
    .potato_loading1_title{
        font-size: 14px;
        color: #FFFFFF;
    }
    .potato_loading3_title{
        display: inline-block;
        padding: 10px 14px;
        border-radius: 10px;
        background-color: rgba(0,0,0,0.8);
        color: #fff;
        max-width: 80%;
        line-height: 0.44rem;
    }
    @keyframes spin{
        from {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }


</style>
